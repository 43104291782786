// import './Recruitment.less';
import vsf, { definePage } from '@vs/vsf-boot';
import { Section } from '@vs/vsf-kit';
import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';

import group from '@/assets/icon/Group.png';
import home from '@/assets/icon/home.png';
import Vector from '@/assets/icon/Vector.png';
import VideoLibrary from '@/assets/icon/VideoLibrary.png';
import Layers from '@/assets/icon/Layers.png';
import QuestionSquare from '@/assets/icon/QuestionSquare.png';

import { getImages, getLabel } from '@/config/zhCn_config';

const Product = (props) => {
  const list = [
    {
      title: getLabel('xiazaizhongxinname'),
      img: Vector,
      link: './downLoadCenter',
    },
    {
      title: getLabel('shipinzhongxinname'),

      img: VideoLibrary,
      link: './VideoCenter',
    },
    {
      title: getLabel('xiangmuanliname'),
      img: Layers,
      link: './ProjectCase',
    },
    {
      title: getLabel('changjianwenti'),
      img: QuestionSquare,
      link: './Faq',
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Section>
      <div className="recruitmentContainer">
        <div className="recruitmentContainer-titleImg">
          <img src={getImages('supportTItile')} alt="" />
        </div>
        <div
          className="recruitmentContainer-content"
          style={{
            background: 'white',
          }}
        >
          <div className="recruitmentContainer-content__title">
            <img
              src={home}
              alt=""
              className="recruitmentContainer-content__title__home"
            />
            <img
              src={group}
              alt=""
              className="recruitmentContainer-content__title__group"
            />
            <div className="recruitmentContainer-content__title__text">
              {getLabel('fuwuzhichi')}
            </div>
          </div>

          <div className="recruitmentContainer-content__center">
            <div className="recruitmentContainer-content__center__top">
              Functions
            </div>
            <div className="recruitmentContainer-content__center__flex">
              {getLabel('changyonggongneng')}
            </div>
          </div>

          <div className="recruitmentContainer-content__box">
            {list?.map((item) => {
              return (
                <>
                  <div
                    className="recruitmentContainer-content__box__item"
                    onClick={() => {
                      vsf?.navigateTo(item?.link);
                    }}
                  >
                    <div className="recruitmentContainer-content__box__item__imgBox">
                      <img
                        src={item?.img}
                        alt=""
                        className="recruitmentContainer-content__box__item__imgBox__pic"
                      />
                    </div>
                    <div className="recruitmentContainer-content__box__item__name">
                      {item?.title}
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </Section>
  );
};

export default definePage(Product);
