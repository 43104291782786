import './VideoCenter.less';
import vsf, { definePage } from '@vs/vsf-boot';
import { Section, Input, Menu, Checkbox, Space } from '@vs/vsf-kit';
import React, { useState, useEffect, useCallback } from 'react';

import group from '@/assets/icon/Group.png';
import home from '@/assets/icon/home.png';
import VideoIcon from '@/assets/icon/Video.png';
import left from '@/assets/icon/left.png';
import right from '@/assets/icon/right.png';

import { getImages, getLabel, isChinese } from '@/config/zhCn_config';

const VedioCenter = (props) => {
  const [dataList, setDataList] = useState();
  const [isplay, setIsplay] = useState(false);
  const [startIndex, setStartIndex] = useState(0);

  const [firstItem, setFirstItem] = useState();
  const [remainingItems, setRemainingItems] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const loadSDataInfo = () => {
    vsf?.services
      ?.VoiceCenterController_getAllByVideoCenterWebQto_f180bf?.({
        qto: {},
      })
      .then((resposne) => {
        if (resposne && resposne?.code === 200) {
          setDataList(resposne?.data);
          setFirstItem(resposne?.data?.[0]);
          setRemainingItems(resposne?.data?.slice(1));
        }
      });
  };
  useEffect(() => {
    loadSDataInfo();
  }, []);

  const safeStartIndex = Math.max(
    0,
    Math.min(startIndex, dataList?.length - 4),
  );

  const renderThumbnails = () => {
    const endIndex = Math.min(safeStartIndex + 4, remainingItems?.length);
    return remainingItems
      ?.slice(safeStartIndex, endIndex)
      .map((item, index) => {
        const actualIndex = safeStartIndex + index;
        return (
          <div
            className="VedioContanier__bottom__center__box"
            key={actualIndex}
            onClick={() => {
              handleItemClick(item);
            }}
          >
            <div className="VedioContanier__bottom__center__box__img">
              <img src={item?.thumbnail} alt="" />
            </div>
            <div className="VedioContanier__bottom__center__box__name">
              {(isChinese() ? item?.videoName : item?.enVideoName) ?? ''}
            </div>
          </div>
        );
      });
  };

  const handleLeftArrowClick = () => {
    if (startIndex > 0) {
      setStartIndex(startIndex - 4);
    }
  };

  const handleRightArrowClick = () => {
    if (startIndex + 4 < remainingItems?.length) {
      setStartIndex(startIndex + 4);
    }
  };
  const handleItemClick = (clickedItem) => {
    setIsplay(false);
    const index = remainingItems.findIndex(
      (item) => item.id === clickedItem.id,
    );

    if (index === -1) return;

    const newRemainingItems = [
      firstItem,
      ...remainingItems.filter((_, i) => i !== index),
    ];

    setFirstItem(clickedItem);
    setRemainingItems(newRemainingItems);
  };

  return (
    <Section>
      <div className="recruitmentContainer">
        <div
          className="recruitmentContainer-content"
          style={{
            background: 'white',
            paddingLeft: 0,
          }}
        >
          <div
            className="recruitmentContainer-content__title"
            style={{
              paddingLeft: '3.81rem',
            }}
          >
            <img
              src={home}
              alt=""
              className="recruitmentContainer-content__title__home"
            />
            <img
              src={group}
              alt=""
              className="recruitmentContainer-content__title__group"
            />
            <div
              className="newsDetailContainer-content__title__textNav"
              onClick={() => {
                vsf?.navigateTo('./ServiceSupport');
              }}
            >
              {getLabel('fuwuzhichi')}
            </div>
            <img
              src={group}
              alt=""
              className="recruitmentContainer-content__title__group"
            />
            <div className="recruitmentContainer-content__title__text">
              {getLabel('shipinzhongxinname')}
            </div>
          </div>

          <div
            className="recruitmentContainer-content__center"
            style={{
              marginLeft: '0',
            }}
          >
            <div className="recruitmentContainer-content__center__top">
              Video
            </div>
            <div className="recruitmentContainer-content__center__flex">
              {getLabel('shipinzhongxinname')}
            </div>
          </div>

          <div className="VedioContanier__box">
            <div className="VedioContanier__box__video">
              {isplay ? (
                <>
                  <video controls autoPlay={isplay}>
                    <source
                      src={
                        firstItem?.videoUrl
                          ? JSON?.parse(firstItem?.videoUrl)
                          : ''
                      }
                      type="video/mp4"
                    />
                  </video>
                </>
              ) : (
                <>
                  <img src={firstItem?.thumbnail} alt="" />
                  <div className="VedioContanier__box__video__name">
                    Cherry-Corporate Video
                  </div>

                  <div className="VedioContanier__box__video__Icon">
                    <img
                      src={VideoIcon}
                      alt=""
                      onClick={(e) => {
                        setIsplay(true);
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="VedioContanier__bottom">
            <div
              className="VedioContanier__bottom__left"
              onClick={handleLeftArrowClick}
            >
              {startIndex > 0 && (
                <>
                  <img src={left} alt="Previous" />
                </>
              )}
            </div>

            <div className="VedioContanier__bottom__center">
              {renderThumbnails()}
            </div>
            <div
              className="VedioContanier__bottom__right"
              onClick={handleRightArrowClick}
            >
              {startIndex + 4 < dataList?.length && (
                <>
                  <img src={right} alt="Next" />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default definePage(VedioCenter);
