import ProductRoot from '@pages/ProductRoot';

import Arrowdown from '@/assets/icon/Arrowdown.png';
import AboutUs from '@/pages/AboutUs';
import ConnectUs from '@/pages/ConnectUs';
import DownLoadCenter from '@/pages/downLoadCenter';
import Faq from '@/pages/Faq';
import HomePage from '@/pages/HomePage';
import News from '@/pages/News';
import newsDetail from '@/pages/newsDetail';
import photovoltaic from '@/pages/photovoltaic';
import Product from '@/pages/Product';
import ProductDetail from '@/pages/productDetail';
import ProductSpeDetail from '@/pages/productSpeDetail';
import ProjectCase from '@/pages/projectCase';
import ProjectCaseDetail from '@/pages/projectCaseDetail';
import Recruitment from '@/pages/Recruitment';
import RecruitmentDetail from '@/pages/RecruitmentDetail';
import ServiceSupport from '@/pages/ServiceSupport';
import VideoCenter from '@/pages/VideoCenter';

const routes = [
  {
    // 首页
    path: '/HomePage',
    component: HomePage,
    parentRoute: '/HomePage',
    exact: true,
    label: '首页',
    labelCn: '首页',
    labelEn: 'Home',
  },
  {
    // 产品
    path: '/Product',
    component: Product,
    parentRoute: '/Product',
    icon: Arrowdown,
    exact: true,
    label: '产品',
    labelCn: '产品',
    labelEn: 'Products',
  },

  {
    // 商店
    path: '/Store',
    label: '商店',
    labelCn: '商店',
    labelEn: 'Store',
    link: 'https://store.cherrysolution.net/',
  },
  {
    // 新闻资讯
    path: '/News',
    component: News,
    parentRoute: '/News',
    exact: true,
    label: '新闻资讯',
    labelCn: '新闻资讯',
    labelEn: 'News',
  },
  {
    // 服务支持
    path: '/ServiceSupport',
    component: ServiceSupport,
    exact: true,
    label: '服务支持',
    parentRoute: '/ServiceSupport',
    labelCn: '服务支持',
    labelEn: 'Support',
  },

  {
    // 关于我们
    path: '/AboutUs',
    component: AboutUs,
    parentRoute: '/AboutUs',
    exact: true,
    label: '关于桥跃',
    labelCn: '关于桥跃',
    labelEn: 'About',
  },

  {
    // 产品详情
    path: '/productSpeDetail',
    component: ProductSpeDetail,
    parentRoute: '/Product',
    exact: true,
    label: '光伏产品详情',
    hideInMenu: true,
  },

  {
    // 产品详情
    path: '/productDetail',
    component: ProductDetail,
    parentRoute: '/Product',
    exact: true,
    label: '产品详情',
    hideInMenu: true,
  },

  {
    // 光伏逆变器
    path: '/photovoltaic',
    exact: true,
    component: photovoltaic,
    parentRoute: '/Product',
    label: '光伏逆变器',
    hideInMenu: true,
  },

  // {
  //   path: '/ProductMange/Product',
  //   component: ProductRoot,
  //   label: '产品',
  //   routes: [
  //     {
  //       path: '/ProductMange/Product',
  //       exact: true,
  //       component: Product,
  //       label: '产品',
  //     },
  //     {
  //       path: '/ProductMange/photovoltaic',
  //       exact: true,
  //       component: photovoltaic,
  //       label: '光伏逆变器',
  //       hideInMenu: true,
  //     },
  //   ],
  // },

  {
    // 招贤纳士
    path: '/Recruitment',
    component: Recruitment,
    parentRoute: '/Recruitment',
    exact: true,
    label: '招贤纳士',
    labelCn: '招贤纳士',
    labelEn: 'Join us',
  },

  {
    // 招贤纳士详情
    path: '/RecruitmentDetail',
    component: RecruitmentDetail,
    parentRoute: '/Recruitment',
    exact: true,
    label: '招贤纳士详情',
    hideInMenu: true,
  },

  {
    // 新闻资讯详情
    path: '/newsDetail',
    component: newsDetail,
    parentRoute: '/News',
    exact: true,
    label: '新闻资讯详情',
    hideInMenu: true,
  },

  {
    // 下载中心
    path: '/downLoadCenter',
    component: DownLoadCenter,
    exact: true,
    label: '下载中心',
    parentRoute: '/ServiceSupport',
    hideInMenu: true,
  },
  {
    // 视频中心
    path: '/VideoCenter',
    component: VideoCenter,
    exact: true,
    label: '视频中心',
    parentRoute: '/ServiceSupport',
    hideInMenu: true,
  },
  {
    // 项目案例
    path: '/ProjectCase',
    component: ProjectCase,
    exact: true,
    label: '项目案例',
    parentRoute: '/ServiceSupport',
    hideInMenu: true,
  },
  {
    // 项目案例详情
    path: '/ProjectCaseDetail',
    component: ProjectCaseDetail,
    exact: true,
    label: '项目案例详情',
    parentRoute: '/ServiceSupport',
    hideInMenu: true,
  },
  {
    // 常见问题
    path: '/Faq',
    component: Faq,
    exact: true,
    label: '常见问题',
    parentRoute: '/ServiceSupport',
    hideInMenu: true,
  },
  {
    // 联系我们
    path: '/ConnectUs',
    component: ConnectUs,
    exact: true,
    label: '联系我们',
    parentRoute: '/ConnectUs',
    labelCn: '联系我们',
    labelEn: 'Contact',
  },
];

export default routes;
