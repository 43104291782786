import './Faq.less';
import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  Input,
  Menu,
  Checkbox,
  Space,
  Pagination,
  Collapse,
  CollapsePanel,
} from '@vs/vsf-kit';
import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';

import group from '@/assets/icon/Group.png';
import home from '@/assets/icon/home.png';
import Photo from '@/assets/image/Photo.png';

import { getImages, getLabel, isChinese } from '@/config/zhCn_config';

const Faq = (props) => {
  const [pageNum, setPageNum] = useState(0);
  const [pageSize, setPageSize] = useState(6);
  const [total, setTotal] = useState();

  const [dataList, setDataList] = useState();

  const loadSDataInfo = () => {
    vsf?.services
      ?.ProductCaseController_getPagedByProblemQto_0ee1a5?.({
        qto: {
          from: pageNum * pageSize ?? 0,
          size: pageSize ?? 6,
          firstLevelTypeIs: '',
          secondLevelTypeIs: '',
        },
        ext: {},
      })
      .then((resposne) => {
        if (resposne && resposne?.code === 200) {
          setDataList(resposne?.data?.result);
          setTimeout(() => {
            setTotal(resposne?.data?.count);
          }, 1);
        }
      });
  };

  useEffect(() => {
    loadSDataInfo();
  }, [pageNum]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (e) => {
    setPageNum(e - 1);
  };

  const data = [
    {
      key: '1',
      label: '什么是MLPE组件级电力电子技术?',
      children: (
        <p>
          组件级电力电子Module Level Power
          Electronics，字面意思即为组件级别的电子设备。组件级电力电子，在太阳能光伏系统中，能对单个或几个光伏组件进行精细化控制的电力电子设备，实现逆变、监控、功率优化、关断等功能。该技术包含电力电子、半导体器件、通讯、云计算及高可靠制造等多个方面，目前现有产品系列包括微型逆变器、优化器、组件级关断器等。
        </p>
      ),
    },
    {
      key: '2',
      label: '什么是MLPE组件级电力电子技术?',
      children: (
        <p>
          组件级电力电子Module Level Power
          Electronics，字面意思即为组件级别的电子设备。组件级电力电子，在太阳能光伏系统中，能对单个或几个光伏组件进行精细化控制的电力电子设备，实现逆变、监控、功率优化、关断等功能。该技术包含电力电子、半导体器件、通讯、云计算及高可靠制造等多个方面，目前现有产品系列包括微型逆变器、优化器、组件级关断器等。
        </p>
      ),
    },
    {
      key: '3',
      label: '什么是MLPE组件级电力电子技术?',
      children: (
        <p>
          组件级电力电子Module Level Power
          Electronics，字面意思即为组件级别的电子设备。组件级电力电子，在太阳能光伏系统中，能对单个或几个光伏组件进行精细化控制的电力电子设备，实现逆变、监控、功率优化、关断等功能。该技术包含电力电子、半导体器件、通讯、云计算及高可靠制造等多个方面，目前现有产品系列包括微型逆变器、优化器、组件级关断器等。
        </p>
      ),
    },
    {
      key: '4',
      label: '什么是MLPE组件级电力电子技术?',
      children: (
        <p>
          组件级电力电子Module Level Power
          Electronics，字面意思即为组件级别的电子设备。组件级电力电子，在太阳能光伏系统中，能对单个或几个光伏组件进行精细化控制的电力电子设备，实现逆变、监控、功率优化、关断等功能。该技术包含电力电子、半导体器件、通讯、云计算及高可靠制造等多个方面，目前现有产品系列包括微型逆变器、优化器、组件级关断器等。
        </p>
      ),
    },
    {
      key: '5',
      label: '什么是MLPE组件级电力电子技术?',
      children: (
        <p>
          组件级电力电子Module Level Power
          Electronics，字面意思即为组件级别的电子设备。组件级电力电子，在太阳能光伏系统中，能对单个或几个光伏组件进行精细化控制的电力电子设备，实现逆变、监控、功率优化、关断等功能。该技术包含电力电子、半导体器件、通讯、云计算及高可靠制造等多个方面，目前现有产品系列包括微型逆变器、优化器、组件级关断器等。
        </p>
      ),
    },
    {
      key: '6',
      label: '什么是MLPE组件级电力电子技术?',
      children: (
        <p>
          组件级电力电子Module Level Power
          Electronics，字面意思即为组件级别的电子设备。组件级电力电子，在太阳能光伏系统中，能对单个或几个光伏组件进行精细化控制的电力电子设备，实现逆变、监控、功率优化、关断等功能。该技术包含电力电子、半导体器件、通讯、云计算及高可靠制造等多个方面，目前现有产品系列包括微型逆变器、优化器、组件级关断器等。
        </p>
      ),
    },
    {
      key: '7',
      label: '什么是MLPE组件级电力电子技术?',
      children: (
        <p>
          组件级电力电子Module Level Power
          Electronics，字面意思即为组件级别的电子设备。组件级电力电子，在太阳能光伏系统中，能对单个或几个光伏组件进行精细化控制的电力电子设备，实现逆变、监控、功率优化、关断等功能。该技术包含电力电子、半导体器件、通讯、云计算及高可靠制造等多个方面，目前现有产品系列包括微型逆变器、优化器、组件级关断器等。
        </p>
      ),
    },
    {
      key: '8',
      label: '什么是MLPE组件级电力电子技术?',
      children: (
        <p>
          组件级电力电子Module Level Power
          Electronics，字面意思即为组件级别的电子设备。组件级电力电子，在太阳能光伏系统中，能对单个或几个光伏组件进行精细化控制的电力电子设备，实现逆变、监控、功率优化、关断等功能。该技术包含电力电子、半导体器件、通讯、云计算及高可靠制造等多个方面，目前现有产品系列包括微型逆变器、优化器、组件级关断器等。
        </p>
      ),
    },
    {
      key: '9',
      label: '什么是MLPE组件级电力电子技术?',
      children: (
        <p>
          组件级电力电子Module Level Power
          Electronics，字面意思即为组件级别的电子设备。组件级电力电子，在太阳能光伏系统中，能对单个或几个光伏组件进行精细化控制的电力电子设备，实现逆变、监控、功率优化、关断等功能。该技术包含电力电子、半导体器件、通讯、云计算及高可靠制造等多个方面，目前现有产品系列包括微型逆变器、优化器、组件级关断器等。
        </p>
      ),
    },
    {
      key: '10',
      label: '什么是MLPE组件级电力电子技术?',
      children: (
        <p>
          组件级电力电子Module Level Power
          Electronics，字面意思即为组件级别的电子设备。组件级电力电子，在太阳能光伏系统中，能对单个或几个光伏组件进行精细化控制的电力电子设备，实现逆变、监控、功率优化、关断等功能。该技术包含电力电子、半导体器件、通讯、云计算及高可靠制造等多个方面，目前现有产品系列包括微型逆变器、优化器、组件级关断器等。
        </p>
      ),
    },
    {
      key: '11',
      label: '什么是MLPE组件级电力电子技术?',
      children: (
        <p>
          组件级电力电子Module Level Power
          Electronics，字面意思即为组件级别的电子设备。组件级电力电子，在太阳能光伏系统中，能对单个或几个光伏组件进行精细化控制的电力电子设备，实现逆变、监控、功率优化、关断等功能。该技术包含电力电子、半导体器件、通讯、云计算及高可靠制造等多个方面，目前现有产品系列包括微型逆变器、优化器、组件级关断器等。
        </p>
      ),
    },
  ];
  return (
    <Section>
      <div className="recruitmentContainer">
        <div
          className="recruitmentContainer-content"
          style={{
            background: 'white',
          }}
        >
          <div className="recruitmentContainer-content__title">
            <img
              src={home}
              alt=""
              className="recruitmentContainer-content__title__home"
            />
            <img
              src={group}
              alt=""
              className="recruitmentContainer-content__title__group"
            />
            <div
              className="newsDetailContainer-content__title__textNav"
              onClick={() => {
                vsf?.navigateTo('./ServiceSupport');
              }}
            >
              {getLabel('fuwuzhichi')}
            </div>
            <img
              src={group}
              alt=""
              className="recruitmentContainer-content__title__group"
            />
            <div className="newsDetailContainer-content__title__textNav">
              {getLabel('changjianwentiname')}
            </div>
          </div>

          <div className="recruitmentContainer-content__center">
            <div
              className="recruitmentContainer-content__center__top"
              style={{
                width: '3.125rem',
              }}
            >
              FAQ
            </div>
            <div className="recruitmentContainer-content__center__flex">
              {getLabel('changjianwentiname')}
            </div>
          </div>

          <div className="Faq_center">
            <Collapse
              expandIconPosition={'end'}
              className="Faq_center__box"
              bordered={false}
              ghost
              defaultActiveKey={[1]}
            >
              {dataList?.map((item) => {
                return (
                  <>
                    <CollapsePanel
                      key={item?.sort}
                      header={
                        (isChinese() ? item?.problem : item?.enProblem) ?? ''
                      }
                      children={
                        (isChinese() ? item?.answer : item?.enAnswer) ?? ''
                      }
                    ></CollapsePanel>
                  </>
                );
              })}
            </Collapse>
          </div>

          <div className="Faq_Pagination">
            <Pagination
              defaultCurrent={1}
              defaultPageSize={pageSize}
              total={total}
              showSizeChanger={false}
              onChange={handleChange}
              className="my-pagination"
            />
          </div>
        </div>
      </div>
    </Section>
  );
};

export default definePage(Faq);
