/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prettier/prettier */
import './index.less';

import vsf, { definePage, renderRoutes, RouteConfig } from '@vs/vsf-boot';
import { ArrowRightOutlined } from '@vs/vsf-icons';
import { Modal, Popover } from '@vs/vsf-kit';
import { Layout } from '@vs/vsf-kit';
import { useMount } from 'ahooks';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import address from '@/assets/icon/address.png';
import calculator from '@/assets/icon/calculator.png';
import calculatorFour from '@/assets/icon/calculatorFour.png';
import calculatorIcon from '@/assets/icon/calculatorIcon.png';
import calculatorThree from '@/assets/icon/calculatorThree.png';
import calculatorTwo from '@/assets/icon/calculatorTwo.png';
import email from '@/assets/icon/email.png';
import insIcon from '@/assets/icon/insIcon.png';
import phone from '@/assets/icon/phone.png';
import robotIcon from '@/assets/icon/robotIcon.png';
import SocialIcons from '@/assets/icon/SocialIcons.png';
import SocialIcons1 from '@/assets/icon/SocialIcons1.png';
import SocialIcons2 from '@/assets/icon/SocialIcons2.png';
import SocialIcons3 from '@/assets/icon/SocialIcons3.png';
import topGroupIcon from '@/assets/icon/topGroupIcon.png';
import WeChat from '@/assets/icon/WeChat.png';
import you from '@/assets/icon/you.png';
import logo from '@/assets/image/logo.png';
import Rectangle1097 from '@/assets/image/Rectangle1097.png';
import Rectangle1098 from '@/assets/image/Rectangle1098.png';
import Rectangle1099 from '@/assets/image/Rectangle1099.png';
import weChatCode from '@/assets/image/weChatCode.jpg';
import { getLabel, isChinese } from '@/config/zhCn_config';
import storage from '@/utils/storage';

const TitleContainer = styled.div`
  .weChantCode {
    background: #d9d9d9;
    width: 100%;
    color: #5d5d5d;
    font-family: Alibaba PuHuiTi 2;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
  }
`;

const { Sider } = Layout;

type ContainerProps = {
  route: any;
};
const Container: React.FC<any> = (props) => {
  const { route } = props;
  const {
    stores: {
      zhCn: { language, setLanguage },
    },
  } = props;

  const handelChangeLanguage = (v) => {
    setLanguage?.(v);
  };
  const [current, setCurrent] = useState(0);
  const [productDataList, setProductDataList] = useState<any>([]);
  const [selectedKey, setSelectedKey] = useState('/HomePage');
  const pathnName = window.location.pathname;
  const [unfold, setUnfold] = useState(true);

  // 切换字体
  const tabFontFamily = () => {
    // console.log(language, 'language');
    if (language === 'english') {
      // 英文
      const allElements = document.querySelectorAll('div');
      allElements.forEach((element) => {
        //  console.log(element,'element')
        element.style.fontFamily = 'Poppins';
      });
    } else if (language === 'chinese') {
      // 中文
      const allElements = document.querySelectorAll('div');
      allElements.forEach((element) => {
        //  console.log(element,'element')
        element.style.fontFamily = '';
      });
    }
  };

  useEffect(() => {
    loadTopData();
  }, []);

  useEffect(() => {
    tabFontFamily();
    // console.log(language, '切换');
  }, [language]);

  useEffect(() => {
    const parentRoute = route?.routes?.find((item) => {
      return item?.path === pathnName;
    });
    setSelectedKey(parentRoute?.parentRoute);
  }, [selectedKey, route?.routes, pathnName]);

  useMount(() => {
    const dom: HTMLDivElement = document.querySelector(
      '.vsf-layout-header-right-menu-item-product',
    ) as HTMLDivElement;
    const modalContainerDom: HTMLDivElement = document.querySelector(
      '.modalContainer_move',
    ) as HTMLDivElement;
    modalContainerDom?.addEventListener('mouseover', () => {
      modalContainerDom.style.display = 'block';
    });
    modalContainerDom?.addEventListener('mouseout', () => {
      modalContainerDom.style.display = 'none';
    });
    dom?.addEventListener('mouseover', () => {
      modalContainerDom.style.display = 'block';
    });
    dom?.addEventListener('mouseout', () => {
      modalContainerDom.style.display = 'none';
    });

    const dom1: HTMLDivElement = document.querySelector(
      '.vsf-layout-header-right-menu-item-serviceSupport',
    ) as HTMLDivElement;
    const modalContainerDom1: HTMLDivElement = document.querySelector(
      '.modalContainer_moveSer',
    ) as HTMLDivElement;
    modalContainerDom1?.addEventListener('mouseover', () => {
      modalContainerDom1.style.display = 'block';
    });
    modalContainerDom1?.addEventListener('mouseout', () => {
      modalContainerDom1.style.display = 'none';
    });
    dom1?.addEventListener('mouseover', () => {
      modalContainerDom1.style.display = 'block';
    });
    dom1?.addEventListener('mouseout', () => {
      modalContainerDom1.style.display = 'none';
    });

    const dom2: HTMLDivElement = document.querySelector(
      '.vsf-layout-header-right-menu-item-aboutUs',
    ) as HTMLDivElement;
    const modalContainerDom2: HTMLDivElement = document.querySelector(
      '.modalContainer_moveAbo',
    ) as HTMLDivElement;
    modalContainerDom2?.addEventListener('mouseover', () => {
      modalContainerDom2.style.display = 'block';
    });
    modalContainerDom2?.addEventListener('mouseout', () => {
      modalContainerDom2.style.display = 'none';
    });
    dom2?.addEventListener('mouseover', () => {
      modalContainerDom2.style.display = 'block';
    });
    dom2?.addEventListener('mouseout', () => {
      modalContainerDom2.style.display = 'none';
    });
  });
  const renderTitle = () => {
    return (
      <TitleContainer>
        <div
          className="weChantImg"
          style={{
            width: '8.5rem',
            height: '8.6rem',
          }}
        >
          <img
            src={weChatCode}
            alt=""
            style={{ width: '100%', height: '100%' }}
          />
        </div>
      </TitleContainer>
    );
  };

  const topDataList = [
    {
      name: getLabel('photovoltaic'),
      type: 'photovoltaic',
    },
    {
      name: getLabel('robot'),
      type: 'robot',
    },

    {
      name: getLabel('microgrid'),
      type: 'microgrid',
    },
    {
      name: getLabel('powerPlant'),
      type: 'powerPlant',
    },
  ];

  const bottomValue = [
    {
      url: email,
      title: 'Email',
      value: 'info@cherrysolution.net',
    },
    {
      url: phone,
      title: getLabel('dianhua'),
      value: '(86) 19706840835',
    },
    {
      url: address,
      title: getLabel('dizhi'),
      value: getLabel('address'),
    },
  ];

  const bottomRightValue = [
    {
      title: getLabel('guanyuwomen'),
      text: [
        {
          title: getLabel('gonsiyuanjing'),
          value: './AboutUs',
          text: '/AboutUs',
        },
        {
          title: getLabel('gongsijianjie'),
          value: './ConnectUs',
          text: '/ConnectUs',
        },
      ],
    },
    {
      title: getLabel('chanpin'),
      text: [
        {
          title: getLabel('guangfunibianqi'),
          text: '/Product',
          value: './photovoltaic',
        },
        {
          title: getLabel('xunidianchang'),
          text: '/Product',
          value: './productDetail?type=powerPlant',
        },
        {
          title: getLabel('zhinengweiwang'),
          text: '/Product',
          value: './productDetail?type=microgrid',
        },

        {
          title: getLabel('zhinengjiqiren'),
          text: '/Product',
          value: './productDetail?type=robot',
        },
      ],
    },
    {
      title: getLabel('zhaoxiannashi'),
      text: [
        {
          title: getLabel('rezhaozhiwei'),
          text: '/Recruitment',
          value: './Recruitment',
        },
      ],
    },
    {
      title: getLabel('fuwuzhichi'),
      text: [
        {
          title: getLabel('xiazaizhongxin'),
          text: '/ServiceSupport',
          value: './downLoadCenter',
        },
        {
          title: getLabel('shipinzhongxin'),
          text: '/ServiceSupport',
          value: './VideoCenter',
        },
        {
          title: getLabel('changjianwenti'),
          text: '/ServiceSupport',
          value: './Faq',
        },

        {
          title: getLabel('xiangmuanli'),
          text: '/ServiceSupport',
          value: './ProjectCase',
        },
      ],
    },
    // {
    //   title: getLabel('lianxiwomen'),
    //   text: [
    //     {
    //       icon: WeChat,
    //     },
    //   ],
    // },
  ];

  const iconList = [
    {
      icon: WeChat,
      pop: 'weChat',
    },
    {
      icon: SocialIcons2,
      link: 'https://www.facebook.com/people/Cherry-solution/61561863390096/',
    },
    {
      icon: SocialIcons3,
      link: 'https://www.instagram.com/cherry.solution/',
    },
    {
      icon: insIcon,
      link: 'https://www.linkedin.com/company/cherry-netzero/?viewAsMember=true',
    },
    {
      icon: you,
      link: 'https://youtube.com/@cherry_solution?si=0xrXCFmHrWv1gXT9',
    },
    {
      icon: SocialIcons1,
      link: 'https://www.tiktok.com/@cherrysolution?is_from_webapp=1&sender_device=pc',
    },
    {
      icon: SocialIcons,
      link: 'https://x.com/CherrySolution_',
    },
  ];

  const serviceList = [
    {
      title: getLabel('xiazaizhongxinname'),
      value: './downLoadCenter',
    },
    {
      title: getLabel('shipinzhongxinname'),
      value: './VideoCenter',
    },

    {
      title: getLabel('xiangmuanliname'),
      value: './ProjectCase',
    },
    {
      title: getLabel('changjianwenti'),
      value: './Faq',
    },
  ];

  const aboutList = [
    {
      title: getLabel('gonsiyuanjing'),
      value: './AboutUs',
    },
    {
      title: getLabel('zhaoxiannashi'),
      value: './Recruitment',
    },
    {
      title: getLabel('gongsijianjie'),
      value: './ConnectUs',
    },
  ];

  const handleChangeRoute = (item) => {
    if (item?.link) {
      window.open(item?.link);
    } else {
      setSelectedKey(item?.parentRoute);
      vsf?.navigateTo(item?.path);
    }
  };

  const handleNavagite = () => {
    vsf.closeModal('preview');

    if (current === 1) {
      vsf?.navigateTo('./productDetail?type=robot');
    } else if (current === 2) {
      vsf?.navigateTo('./productDetail?type=powerPlant');
    } else if (current === 3) {
      vsf?.navigateTo('./productDetail?type=microgrid');
    }
  };

  const getMenu = () => {
    const filteredRoutes = route?.routes.filter(
      (route) =>
        route.path !== '/HomePage' &&
        route.path !== '/Recruitment' &&
        route.path !== '/ConnectUs',
    );

    return filteredRoutes?.map((item) => ({
      ...item,
      label: language === 'chinese' ? item?.labelCn : item?.labelEn,
    }));
  };

  const loadTopData = () => {
    vsf?.services
      ?.CoreProductController_queryTopProduct_b23d38()
      .then((res) => {
        if (res && res?.code === 200) {
          const miniInverter = res?.data?.miniInverter?.map((ele) => {
            return {
              ...ele,
              imgValue: JSON.parse(ele?.img ?? '[]'),
            };
          });
          const outdoorInverter = res?.data?.outdoorInverter?.map((ele) => {
            return {
              ...ele,
              imgValue: JSON.parse(ele?.img ?? '[]'),
            };
          });
          const workInverter = res?.data?.workInverter?.map((ele) => {
            return {
              ...ele,
              imgValue: JSON.parse(ele?.img ?? '[]'),
            };
          });
          const attachment = res?.data?.attachment?.map((ele) => {
            return {
              ...ele,
              imgValue: JSON.parse(ele?.img ?? '[]'),
            };
          });

          const dataList = [
            {
              // name: getLabel('miniInverter'),
              name: '微型逆变器',
              enName: 'Microinverter',
              icon: calculator,
              list: miniInverter,
              value: 'MINI_INVERTER',
            },
            {
              // name: getLabel('outdoorInverter'),
              name: '户用光储',
              enName: 'Residential',
              icon: calculatorTwo,
              list: outdoorInverter,
              value: 'OUTDOOR_INVERTER',
            },
            {
              // name: getLabel('commerceInverter'),
              // name: '工商业光伏逆变器',
              name: '工商业储能',
              // enName: 'Commercial & Industrial PCS',
              enName: 'Commercial & Industrial',
              icon: calculatorThree,
              list: workInverter,
              value: 'COMMERCE_INVERTER',
            },
            {
              // name: getLabel('attachment'),
              // name: '配件',
              name: '其他产品',
              enName: 'Accessories',
              icon: calculatorFour,
              list: attachment,
              value: 'ATTACHMENT',
            },
          ];
          setProductDataList(dataList);
        }
      });
  };

  return (
    <div className="vsf-layout">
      <div className="vsf-layout-header-wrapper">
        <div className="mask" />
        <div className="vsf-layout-header">
          <div className="vsf-layout-header-left">
            <img
              src={logo}
              className="vsf-layout-header-left-icon"
              onClick={() => {
                vsf?.navigateTo('./HomePage');
              }}
            ></img>
          </div>
          <div className="vsf-layout-header-right">
            <div className="vsf-layout-header-right-menu">
              {getMenu()?.map((item, index) => {
                return (
                  <>
                    <div>
                      {!item?.hideInMenu && (
                        <>
                          <div
                            className={
                              item?.path === '/Product'
                                ? 'vsf-layout-header-right-menu-item vsf-layout-header-right-menu-item-product'
                                : item?.path === '/ServiceSupport'
                                  ? 'vsf-layout-header-right-menu-item vsf-layout-header-right-menu-item-serviceSupport'
                                  : item?.path === '/AboutUs'
                                    ? 'vsf-layout-header-right-menu-item vsf-layout-header-right-menu-item-aboutUs'
                                    : 'vsf-layout-header-right-menu-item'
                            }
                            key={index}
                          >
                            <div
                              onMouseEnter={() => {
                                // item?.icon &&
                                vsf.openModal('preview');
                              }}
                              onClick={() => {
                                handleChangeRoute(item);
                              }}
                            >
                              {item?.label}
                            </div>

                            {/* {item?.icon && (
                              <>
                                <img
                                  src={item?.icon}
                                  alt=""
                                  className="Arrowdown"
                                />
                              </>
                            )} */}

                            <div
                              className={
                                selectedKey === item?.parentRoute
                                  ? 'vsf-layout-header-right-menu-cur'
                                  : ''
                              }
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </>
                );
              })}
            </div>
            <div className="vsf-layout-header-right-btn">
              <div
                className={
                  language === 'chinese'
                    ? 'vsf-layout-header-right-btn-left'
                    : 'vsf-layout-header-right-btn-leftDefult'
                }
                onClick={() => {
                  // tabFontFamily()
                  handelChangeLanguage('chinese');
                }}
              >
                中
              </div>
              <div
                className={
                  language === 'english'
                    ? 'vsf-layout-header-right-btn-right'
                    : 'vsf-layout-header-right-btn-rightDefult'
                }
                onClick={() => {
                  // tabFontFamily();
                  handelChangeLanguage('english');
                }}
              >
                En
              </div>
            </div>
          </div>
        </div>
        <div className="modalContainer_move">
          <div
            className="modalContainer"
            style={{ height: unfold ? '28.75rem' : '42rem' }}
          >
            <div className="modalContainer-left">
              <div className="modalContainer-left__top">
                <div className="modalContainer-left__middle">
                  {getLabel('productLabel')}
                </div>
              </div>
              <div className="modalContainer-left__box">
                {topDataList?.map((item, index) => {
                  return (
                    <>
                      <div
                        className={
                          current === index
                            ? 'modalContainer-left__box__item'
                            : 'modalContainer-left__box__itemNot'
                        }
                        onClick={() => {
                          setCurrent(index);
                        }}
                      >
                        <div
                          className={
                            current === index
                              ? 'modalContainer-left__box__item-background'
                              : 'modalContainer-left__box__itemNot-background'
                          }
                        />
                        <div
                          className={
                            current === index ? 'itemDots' : 'itemDotsNot'
                          }
                        ></div>
                        <div
                          className={
                            current === index ? 'itemName' : 'itemNameNot'
                          }
                        >
                          {item?.name}
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>

            <div className="modalContainer-right">
              {current > 0 ? (
                <>
                  <div className="modalContainer-right__box">
                    <div className="modalContainer-right__box__title">
                      <img
                        src={
                          current === 1
                            ? robotIcon
                            : current === 2
                              ? calculatorIcon
                              : current === 3
                                ? topGroupIcon
                                : ''
                        }
                        alt=""
                        className="modalContainer-right__box__title__icon"
                      />
                      <div className="modalContainer-right__box__title__text">
                        {current === 1
                          ? getLabel('zhinengjiqiren')
                          : current === 2
                            ? getLabel('xunidianchang')
                            : current === 3
                              ? getLabel('zhinengweiwang')
                              : ''}
                      </div>
                    </div>
                    <div
                      className="modalContainer-right__box__imgRightBox"
                      onClick={handleNavagite}
                    >
                      <img
                        src={
                          current === 1
                            ? Rectangle1097
                            : current === 2
                              ? Rectangle1099
                              : current === 3
                                ? Rectangle1098
                                : ''
                        }
                        alt=""
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="modalContainer-right__speBox">
                    {productDataList?.map((item) => {
                      return (
                        <>
                          <div className="modalContainer-right__speBox__item">
                            <div className="modalContainer-right__speBox__item__title">
                              <img
                                src={item?.icon}
                                alt=""
                                className="modalContainer-right__speBox__item__title__icon"
                              />
                              <div className="modalContainer-right__speBox__item__title__text">
                                {isChinese() ? item?.name : item?.enName}
                              </div>
                            </div>

                            <div className="modalContainer-right__speBox__item__con">
                              {item?.list?.map((cur, index) => {
                                return (
                                  <>
                                    <div
                                      className="modalContainer-right__speBox__item__con__box"
                                      onClick={() => {
                                        vsf?.closeModal('preview');

                                        vsf?.navigateTo(
                                          `./productSpeDetail?type=${item?.value}&id=${cur?.id}`,
                                        );
                                      }}
                                    >
                                      <div className="modalContainer-right__speBox__item__con__box__item">
                                        <div className="modalContainer-right__speBox__item__con__box__item__imgBox">
                                          <img
                                            loading="lazy"
                                            src={cur?.imgValue}
                                            alt=""
                                          />
                                        </div>
                                      </div>
                                      <div
                                        className="modalContainer-right__speBox__item__con__box__item__name"
                                        style={
                                          isChinese()
                                            ? { fontSize: '0.875rem' }
                                            : { fontSize: '0.75rem' }
                                        }
                                      >
                                        {isChinese() ? cur?.name : cur?.enName}
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </>
              )}
            </div>
          </div>
          <p
            className="btn"
            onClick={() => {
              setUnfold(!unfold);
            }}
          >
            {unfold ? '展开' : '缩放'}
          </p>
        </div>

        <div className="modalContainer_moveSer">
          <div className="moveSerBox">
            {serviceList?.map((item) => {
              return (
                <>
                  <div
                    className="moveItme"
                    onClick={() => {
                      vsf?.navigateTo(`${item?.value}`);
                    }}
                  >
                    {item?.title}
                  </div>
                </>
              );
            })}
          </div>
        </div>

        <div className="modalContainer_moveAbo">
          <div className="moveSerBox">
            {aboutList?.map((item) => {
              return (
                <>
                  <div
                    className="moveItme"
                    onClick={() => {
                      vsf?.navigateTo(`${item?.value}`);
                    }}
                  >
                    {item?.title}
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
      <div className="vsf-layout-content">
        <div className="vsf-layout-main">
          {renderRoutes(route.routes, route.redirect)}
        </div>
      </div>

      <div className="vsf-layout-bottom">
        <div className="vsf-layout-bottom-center">
          <div className="vsf-layout-bottom-center__left">
            <div className="vsf-layout-bottom-center__left__logo"></div>
            {bottomValue?.map((item) => {
              return (
                <>
                  <div className="vsf-layout-bottom-center__left__item">
                    <div
                      className="vsf-layout-bottom-center__left__item__icon"
                      style={{ backgroundImage: `url(${item?.url})` }}
                    ></div>
                    <div className="vsf-layout-bottom-center__left__item__value">
                      <div className="value_title">{item?.title}</div>
                      <div className="value_con">{item?.value}</div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
          <div className="vsf-layout-bottom-center__right">
            {bottomRightValue?.map((item) => {
              return (
                <>
                  <div className="vsf-layout-bottom-center__right__item">
                    <div className="vsf-layout-bottom-center__right__item__title">
                      {item?.title}
                    </div>
                    <div className="vsf-layout-bottom-center__right__item__list">
                      {item?.text?.map((ele) => {
                        return (
                          <>
                            <div
                              className="vsf-layout-bottom-center__right__item__listItem"
                              onClick={() => {
                                vsf?.navigateTo(ele?.value);
                              }}
                            >
                              {ele?.title}
                              {/* {ele?.icon && (
                                <>
                                  <div className="vsf-layout-bottom-center__right__item__listItemImg">
                                    <Popover
                                      className="my-custom-popover"
                                      placement="bottom"
                                      title={renderTitle}
                                      content={getLabel('guanfanggongzhonghao')}
                                    >
                                      <img src={ele?.icon} alt="" />
                                    </Popover>
                                  </div>
                                </>
                              )} */}
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
        <div className="vsf-layout-bottom-footer">
          <div className="vsf-layout-bottom-footer__left">
            {getLabel('banquan')}
          </div>
          <div className="vsf-layout-bottom-footer__right">
            {iconList?.map((item) => {
              return (
                <>
                  {item?.pop ? (
                    <>
                      <div className="vsf-layout-bottom-center__right__item__listItemImg">
                        <Popover
                          className="my-custom-popover"
                          placement="bottom"
                          title={renderTitle}
                          content={getLabel('guanfanggongzhonghao')}
                        >
                          <img src={item?.icon} alt="" />
                        </Popover>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="vsf-layout-bottom-center__right__item__listItemImg">
                        <a href={item.link} target="_blank" rel="noreferrer">
                          <img src={item?.icon} alt="" />
                        </a>
                      </div>
                    </>
                  )}
                </>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default definePage(Container);
