import './newsDetail.less';
import vsf, { definePage } from '@vs/vsf-boot';
import { Section, message } from '@vs/vsf-kit';
import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import home from '@/assets/icon/home.png';
import group from '@/assets/icon/Group.png';
import newCalendar from '@/assets/icon/newCalendar.png';
import dayjs from 'dayjs';
import storage from '@/utils/storage';
import { getImages, getLabel, isChinese } from '@/config/zhCn_config';
storage.get('language') === 'chinese';
const newsDetail = (props) => {
  const [id, setId] = useState();
  const [detail, setDetail] = useState({});
  const loadSDataInfo = () => {
    vsf?.services
      ?.InformationManagementController_getInformationInfoVoById_4c56d2?.({
        id: id ?? props?.routes?.query?.id,
      })
      .then((resposne) => {
        if (resposne && resposne?.code === 200) {
          setDetail(resposne?.data);
        }
      });
  };

  useEffect(() => {
    loadSDataInfo();
    window.scrollTo(0, 0);
  }, [id]);
  return (
    <Section>
      <div className="newsDetailContainer">
        <div className="newsDetailContainer-content">
          <div className="newsDetailContainer-content__title">
            <img
              src={home}
              alt=""
              className="newsDetailContainer-content__title__home"
            />
            <img
              src={group}
              alt=""
              className="newsDetailContainer-content__title__group"
            />
            <div
              className="newsDetailContainer-content__title__textNav"
              onClick={() => {
                vsf?.navigateTo('./News');
              }}
            >
              {getLabel('xinweizixun')}
            </div>
            <img
              src={group}
              alt=""
              className="newsDetailContainer-content__title__group"
            />
            <div className="newsDetailContainer-content__title__text">
              {(isChinese() ? detail?.title : detail?.enTitle) ?? ''}
            </div>
          </div>
          <div className="newsDetailContainer-content__center">
            <div className="newsDetailContainer-content__center__title">
              <div className="newsDetailContainer-content__center__title__top">
                {(isChinese() ? detail?.title : detail?.enTitle) ?? ''}
              </div>
              <div className="newsDetailContainer-content__center__title__time">
                <img
                  src={newCalendar}
                  alt=""
                  className="newCalendar"
                  loading="lazy"
                />
                <div className="newCalendarTime">
                  {dayjs(detail?.pubTime).format('YYYY-MM-DD') ?? '-'}
                </div>
              </div>
            </div>
            <div className="newsDetailContainer-content__center__top">
              {(isChinese() ? detail?.subTitle : detail?.enSubTitle) ?? ''}
            </div>

            <div
              className="newsDetailContainer-content__center__rich"
              dangerouslySetInnerHTML={{
                __html: isChinese() ? detail?.content : detail?.enContent,
              }}
            ></div>

            <div className="newsDetailContainer-content__center__foot">
              <div
                className="newsDetailContainer-content__center__foot__prev"
                onClick={() => {
                  setId(detail?.onPageId);
                }}
              >
                <div>
                  上一篇:
                  {(isChinese() ? detail?.onPageName : detail?.enOnPageName) ??
                    ''}
                </div>
              </div>

              <div
                className="newsDetailContainer-content__center__foot__next"
                onClick={() => {
                  setId(detail?.nextPageId);
                }}
              >
                <div>
                  下一篇:
                  {(isChinese()
                    ? detail?.nextPageName
                    : detail?.enNextPageName) ?? ''}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default definePage(newsDetail);
