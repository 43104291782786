import { createServices } from '@vs/vsf-boot';
export default createServices({
  FrontPageController_getAllByFrontPageQto_bafe67: {
    method: 'post',
    url: '/api/front-page/get-all-by-front-page-qto',
    parameterFomatter: (data?: { qto: FrontPageQto50A331 }) => data,
    responseFormatter: (_, __, data?: FrontPageVoB292A3[]) => data,
  },
  FrontPageController_getAllByAboutUsQto_0487ea: {
    method: 'post',
    url: '/api/front-page/get-all-by-about-us-qto',
    parameterFomatter: (data?: { qto: AboutUsQto50A331 }) => data,
    responseFormatter: (_, __, data?: AboutUsVoB292A3[]) => data,
  },
  IndustryVoiceController_getPagedByIndustryVoiceQto_6fac24: {
    method: 'post',
    url: '/api/front-page/get-paged-by-industry-voice-qto',
    parameterFomatter: (data?: { qto: IndustryVoiceQto50A331 }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: IndustryVoiceVoB292A3[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  InformationManagementController_getPagedByInformationInfoQto_a268b7: {
    method: 'post',
    url: '/api/information-management/get-paged-by-information-info-qto',
    parameterFomatter: (data?: { qto: InformationInfoQtoB3Fc47 }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: InformationInfoVo04D5D5[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  MessageController_createMessage_4501e0: {
    method: 'post',
    url: '/api/information-management/create-message',
    parameterFomatter: (data?: {
      btoParam: CreateMessageBto934887;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: OnlineMessageVo04D5D5) => data,
  },
  RecruitmentController_getPagedByRecruitmentQto_426d6a: {
    method: 'post',
    url: '/api/information-management/get-paged-by-recruitment-qto',
    parameterFomatter: (data?: { qto: RecruitmentQtoB3Fc47 }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: RecruitmentVo04D5D5[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  RecruitmentController_getRecruitmentVoById_d7e03c: {
    method: 'post',
    url: '/api/information-management/get-recruitment-vo-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: RecruitmentVo04D5D5) => data,
  },
  InformationManagementController_getInformationInfoVoById_4c56d2: {
    method: 'post',
    url: '/api/information-management/get-information-info-vo-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: InformationInfoVo04D5D5) => data,
  },
  CoreProductController_queryCompleteProduct_0ca184: {
    method: 'post',
    url: '/api/core-product/query-complete-product',
    parameterFomatter: (data?: {
      queryAllProductBvo: QueryAllProductBvo3E143B;
    }) => data,
    responseFormatter: (_, __, data?: CompleteProductVoEd7Fa8) => data,
  },
  ProductTypeController_getAllByProductTypeQto_6d15e1: {
    method: 'post',
    url: '/api/core-product/get-all-by-product-type-qto',
    parameterFomatter: (data?: { qto: ProductTypeQto252246 }) => data,
    responseFormatter: (_, __, data?: ProductTypeVoEd7Fa8[]) => data,
  },
  AttachmentController_getAllByAttachmentQto_dabbf6: {
    method: 'post',
    url: '/api/core-product/get-all-by-attachment-qto',
    parameterFomatter: (data?: { qto: AttachmentQto252246 }) => data,
    responseFormatter: (_, __, data?: AttachmentVoEd7Fa8[]) => data,
  },
  CoreProductController_getCoreProductVoById_1f53b5: {
    method: 'post',
    url: '/api/core-product/get-core-product-vo-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: CoreProductVoEd7Fa8) => data,
  },
  AttachmentController_getAttachmentVoById_e385b6: {
    method: 'post',
    url: '/api/core-product/get-attachment-vo-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: AttachmentVoEd7Fa8) => data,
  },
  CoreProductController_queryTopProduct_b23d38: {
    method: 'post',
    url: '/api/core-product/query-top-product',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: TopProductVoEd7Fa8) => data,
  },
  AccountManagerController_login_566fd7: {
    method: 'post',
    url: '/api/account-manager/login',
    parameterFomatter: (data?: { btoParam: CreateUserBtoAecbf2 }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  AccountManagerController_getPagedByQueryUserQto_8b40e9: {
    method: 'post',
    url: '/api/account-manager/get-paged-by-query-user-qto',
    parameterFomatter: (data?: { qto: QueryUserQtoB91131 }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: UserInfoVo014Cdd[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  ProductCaseController_createCaseManagement_eedadb: {
    method: 'post',
    url: '/api/product-case/create-case-management',
    parameterFomatter: (data?: {
      btoParam: CreateCaseManagementBtoB303A0;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: ProductCaseManagementVo111917) => data,
  },
  ProductCaseController_createProblem_eaa37b: {
    method: 'post',
    url: '/api/product-case/create-problem',
    parameterFomatter: (data?: {
      btoParam: CreateProblemBtoB303A0;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: CommonProblemVo111917) => data,
  },
  ProductCaseController_deleteCaseManagement_a5eb40: {
    method: 'post',
    url: '/api/product-case/delete-case-management',
    parameterFomatter: (data?: {
      btoParam: DeleteCaseManagementBtoB303A0;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: ProductCaseManagementVo111917) => data,
  },
  ProductCaseController_deleteProblem_3333b7: {
    method: 'post',
    url: '/api/product-case/delete-problem',
    parameterFomatter: (data?: {
      btoParam: DeleteProblemBtoB303A0;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: CommonProblemVo111917) => data,
  },
  ProductCaseController_getPagedByProblemQto_0ee1a5: {
    method: 'post',
    url: '/api/product-case/get-paged-by-problem-qto',
    parameterFomatter: (data?: { qto: ProblemQtoD8B76B }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: CommonProblemVo111917[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  ProductCaseController_getPagedByProductCaseManagementQto_531658: {
    method: 'post',
    url: '/api/product-case/get-paged-by-product-case-management-qto',
    parameterFomatter: (data?: { qto: ProductCaseManagementQtoD8B76B }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: ProductCaseManagementVo111917[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  ProductCaseController_getProductCaseManagementVoById_33efaf: {
    method: 'post',
    url: '/api/product-case/get-product-case-management-vo-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: ProductCaseManagementVo111917) => data,
  },
  ProductCaseController_updateCaseManagement_c9b037: {
    method: 'post',
    url: '/api/product-case/update-case-management',
    parameterFomatter: (data?: {
      btoParam: UpdateCaseManagementBtoB303A0;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: ProductCaseManagementVo111917) => data,
  },
  ProductCaseController_updateProblem_d2b8a6: {
    method: 'post',
    url: '/api/product-case/update-problem',
    parameterFomatter: (data?: {
      btoParam: UpdateProblemBtoB303A0;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: CommonProblemVo111917) => data,
  },
  DocumentCenterFileController_getPagedByDocumentCenterQto_06fbf6: {
    method: 'post',
    url: '/api/document-center-file/get-paged-by-document-center-qto',
    parameterFomatter: (data?: { qto: DocumentCenterQtoAbca44 }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: DocumentCenterFileVo175Fa3[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  VoiceCenterController_getAllByVideoCenterWebQto_f180bf: {
    method: 'post',
    url: '/api/document-center-file/get-all-by-video-center-web-qto',
    parameterFomatter: (data?: { qto: VideoCenterWebQtoAbca44 }) => data,
    responseFormatter: (_, __, data?: VideoCenterVo175Fa3[]) => data,
  },
  VoiceCenterController_getPagedByVideoCenterQto_52b192: {
    method: 'post',
    url: '/api/document-center-file/get-paged-by-video-center-qto',
    parameterFomatter: (data?: { qto: VideoCenterQtoAbca44 }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: VideoCenterVo175Fa3[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
});
