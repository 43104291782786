import './projectCaseDetail.less';
import vsf, { definePage } from '@vs/vsf-boot';
import { Section, message } from '@vs/vsf-kit';
import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import home from '@/assets/icon/home.png';
import group from '@/assets/icon/Group.png';
import dayjs from 'dayjs';
import storage from '@/utils/storage';
import { getImages, getLabel, isChinese } from '@/config/zhCn_config';
import empty from '@/assets/image/empty.png';
import { isMp4Link } from '@/utils/index';

storage.get('language') === 'chinese';
const projectCaseDetail = (props) => {
  const [detail, setDetail] = useState({});
  const loadSDataInfo = () => {
    vsf?.services
      ?.ProductCaseController_getProductCaseManagementVoById_33efaf?.({
        id: props?.routes?.query?.id,
      })
      .then((resposne) => {
        if (resposne && resposne?.code === 200) {
          setDetail(resposne?.data);
        }
      });
  };

  useEffect(() => {
    loadSDataInfo();
    window.scrollTo(0, 0);
  }, []);
  return (
    <Section>
      <div className="newsDetailContainer">
        <div className="newsDetailContainer-content">
          <div className="newsDetailContainer-content__title">
            <img
              src={home}
              alt=""
              className="newsDetailContainer-content__title__home"
            />
            <img
              src={group}
              alt=""
              className="newsDetailContainer-content__title__group"
            />
            <div
              className="newsDetailContainer-content__title__textNav"
              onClick={() => {
                vsf?.navigateTo('./ServiceSupport');
              }}
            >
              {getLabel('fuwuzhichi')}
            </div>
            <img
              src={group}
              alt=""
              className="newsDetailContainer-content__title__group"
            />
            <div
              className="newsDetailContainer-content__title__textNav"
              onClick={() => {
                vsf?.navigateTo('./projectCase');
              }}
            >
              {getLabel('xiangmuanliname')}
            </div>
            <img
              src={group}
              alt=""
              className="newsDetailContainer-content__title__group"
            />
            <div className="newsDetailContainer-content__title__text">
              {(isChinese() ? detail?.name : detail?.enName) ?? ''}
            </div>
          </div>

          {detail ? (
            <>
              <div className="projectCaseDetail__tiltle">
                <div className="projectCaseDetail__tiltle__box">
                  <div className="projectCaseDetail__tiltle__box__text">
                    {getLabel('dizhi5')}
                  </div>
                  <div className="projectCaseDetail__tiltle__box__value">
                    {(isChinese() ? detail?.address : detail?.enAddress) ?? ''}
                  </div>
                </div>
                <div className="projectCaseDetail__tiltle__box">
                  <div className="projectCaseDetail__tiltle__box__text">
                    {getLabel('xmlx')}
                  </div>
                  <div className="projectCaseDetail__tiltle__box__value">
                    {(isChinese()
                      ? detail?.projectType
                      : detail?.enProjectType) ?? ''}
                  </div>
                </div>
                <div className="projectCaseDetail__tiltle__box">
                  <div className="projectCaseDetail__tiltle__box__text">
                    {getLabel('zjrl')}
                  </div>
                  <div className="projectCaseDetail__tiltle__box__value">
                    {detail?.capacity ?? ''}
                  </div>
                </div>
                <div className="projectCaseDetail__tiltle__box">
                  <div className="projectCaseDetail__tiltle__box__text">
                    {getLabel('cplx')}
                  </div>
                  <div className="projectCaseDetail__tiltle__box__value">
                    {(isChinese()
                      ? detail?.productType
                      : detail?.enProductType) ?? ''}
                  </div>
                </div>
              </div>

              <div className="projectCaseDetail__center">
                <div className="projectCaseDetail__center__left">
                  {isMp4Link(
                    detail?.detailImg ? JSON?.parse(detail?.detailImg) : '',
                  ) ? (
                    <>
                      <video controls>
                        <source
                          src={
                            detail?.detailImg
                              ? JSON?.parse(detail?.detailImg ?? [''])
                              : ''
                          }
                          type="video/mp4"
                        />
                      </video>
                    </>
                  ) : (
                    <>
                      <img
                        src={
                          detail?.detailImg
                            ? JSON?.parse(detail?.detailImg ?? [''])
                            : ''
                        }
                        alt=""
                      />
                    </>
                  )}
                </div>
                <div className="projectCaseDetail__center__right">
                  <div className="projectCaseDetail__center__right__title">
                    {(isChinese() ? detail?.name : detail?.enName) ?? ''}
                  </div>
                  <div className="projectCaseDetail__center__right__foot">
                    {(isChinese()
                      ? detail?.projectInfo
                      : detail?.enProjectInfo) ?? ''}
                  </div>
                </div>
              </div>

              <div className="projectCaseDetail__line"></div>
            </>
          ) : (
            <>
              <div className="recruitmentContainer-content__center">
                <div className="recruitmentContainer-content__center__top">
                  Project Case
                </div>
                <div className="recruitmentContainer-content__center__flex">
                  {getLabel('xiangmuanliname')}
                </div>
              </div>
              <div className="empty">
                <div className="empty_img">
                  <img src={empty} alt="" />
                </div>
                <div className="empty_title"> {getLabel('zwnr')}</div>
                <div className="empty_text">{getLabel('ckqtym')}</div>
              </div>
            </>
          )}
        </div>
      </div>
    </Section>
  );
};

export default definePage(projectCaseDetail);
