import './photovoltaic.less';
import vsf, { definePage } from '@vs/vsf-boot';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Section } from '@vs/vsf-kit';
import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';

import group from '@/assets/icon/Group.png';
import home from '@/assets/icon/home.png';

import calanderIcon from '@/assets/icon/calculator.png';
import calculatorTwo from '@/assets/icon/calculatorTwo.png';
import calculatorThree from '@/assets/icon/calculatorThree.png';
import calculatorFour from '@/assets/icon/calculatorFour.png';

import empty from '@/assets/image/empty.png';

import { getImages, getLabel, isChinese } from '@/config/zhCn_config';

const photovoltaicContainer = (props) => {
  const [current, setCurrent] = useState('MINI_INVERTER');
  const [allProduct, setAllProduct] = useState([]);
  const [mainProduct, setMainProduct] = useState([]);
  const [latestProduct, setLatestProduct] = useState([]);
  const [relatedAttachment, setRelatedAttachment] = useState([]);

  const tabList = [
    {
      icon: calanderIcon,
      name: getLabel('miniInverter'),
      value: 'MINI_INVERTER',
    },
    {
      icon: calculatorTwo,
      name: getLabel('outdoorInverter'),
      value: 'OUTDOOR_INVERTER',
    },
    {
      icon: calculatorThree,
      name: getLabel('commerceInverter'),
      value: 'COMMERCE_INVERTER',
    },
    {
      icon: calculatorFour,
      name: getLabel('attachment'),
      value: 'ATTACHMENT',
    },
  ];

  const loadSwiperInfo = () => {
    vsf?.services
      ?.CoreProductController_queryCompleteProduct_0ca184?.({
        queryAllProductBvo: {
          secondaryType: current,
        },
      })
      .then((resposne) => {
        if (resposne && resposne?.code === 200) {
          const latestProduct = resposne?.data?.latestProduct?.map((ele) => {
            return {
              ...ele,
              argumentImg: JSON.parse(ele.argumentImg ?? '[]'),
              enImg: JSON.parse(ele?.enImg ?? '[]'),
              img: JSON.parse(ele?.img ?? '[]'),
            };
          });

          setLatestProduct(latestProduct);

          const AllProduct = resposne?.data?.allProduct?.map((ele) => {
            return {
              ...ele,
              argumentImg: JSON.parse(ele.argumentImg ?? '[]'),
              enImg: JSON.parse(ele?.enImg ?? '[]'),
              img: JSON.parse(ele?.img ?? '[]'),
            };
          });

          setAllProduct(AllProduct);

          const mainProduct = resposne?.data?.mainProduct?.map((ele) => {
            return {
              ...ele,
              argumentImg: JSON.parse(ele.argumentImg ?? '[]'),
              enImg: JSON.parse(ele?.enImg ?? '[]'),
              img: JSON.parse(ele?.img ?? '[]'),
            };
          });

          setMainProduct(mainProduct);
          const relatedAttachment = resposne?.data?.relatedAttachment?.map(
            (ele) => {
              return {
                ...ele,
                argumentImg: JSON.parse(ele.argumentImg ?? '[]'),
                enImg: JSON.parse(ele?.enImg ?? '[]'),
                img: JSON.parse(ele?.img ?? '[]'),
              };
            },
          );
          setRelatedAttachment(relatedAttachment);
        }
      });
    // }
  };

  useEffect(() => {
    loadSwiperInfo();
  }, [current]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Section>
      <div className="photovoltaicContainer">
        <div className="photovoltaicContainer-titleImg">
          <img src={getImages('photovoltaicTitle')} alt="" />
        </div>
        <div className="photovoltaicContainer-content">
          <div className="photovoltaicContainer-content__title">
            <img
              src={home}
              alt=""
              className="photovoltaicContainer-content__title__home"
            />
            <img
              src={group}
              alt=""
              className="photovoltaicContainer-content__title__group"
            />

            <div
              className="photovoltaicContainer-content__title__textNav"
              onClick={() => {
                vsf?.navigateTo('./Product');
              }}
            >
              {getLabel('productLabel')}
            </div>
            <img
              src={group}
              alt=""
              className="photovoltaicContainer-content__title__group"
            />
            <div className="photovoltaicContainer-content__title__text">
              {getLabel('photovoltaic')}
            </div>
          </div>
          <div className="photovoltaicContainer-content__box">
            {tabList?.map((item, index) => {
              return (
                <>
                  <div
                    onClick={() => {
                      setCurrent(item?.value);
                    }}
                    className={
                      current === item?.value
                        ? 'photovoltaicContainer-content__box__item'
                        : 'photovoltaicContainer-content__box__itemUn'
                    }
                  >
                    <img
                      src={item?.icon}
                      alt=""
                      className="photovoltaicContainer-content__box__item__icon"
                    />
                    <div className="photovoltaicContainer-content__box__item__text">
                      {item?.name}
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
        {/* {current !== 'ACCESSORIES' && (
          <>
            <div className="photovoltaicContainer-kernel">
              <div className="photovoltaicContainer-kernel__box">
                <div
                  className="photovoltaicContainer-kernel__box__left"
                  onClick={() => {
                    vsf?.navigateTo(
                      `./productSpeDetail?type=${current}&id=${mainProduct[0]?.id}`,
                    );
                  }}
                >
                  <div className="photovoltaicContainer-kernel__box__left__title">
                    {mainProduct[0]?.name}
                  </div>
                  <div className="photovoltaicContainer-kernel__box__left__sub">
                    <div className="sub__left">
                      了解更多
                      <ArrowRightOutlined className="sub__left__toDetailIcon" />
                      <div className="sub__leftLine"></div>
                    </div>
                    <div
                      className="sub__right"
                      dangerouslySetInnerHTML={{
                        __html: mainProduct[0]?.content,
                      }}
                    ></div>
                  </div>
                  <div className="photovoltaicContainer-kernel__box__left__imgBox">
                    <img src={mainProduct[0]?.img} alt="" loading="lazy" />
                  </div>
                </div>
                <div className="photovoltaicContainer-kernel__box__right">
                  <div
                    className="photovoltaicContainer-kernel__box__right__top"
                    onClick={() => {
                      vsf?.navigateTo(
                        `./productSpeDetail?type=${current}&id=${mainProduct[1]?.id}`,
                      );
                    }}
                  >
                    <div className="photovoltaicContainer-kernel__box__right__top__left">
                      <div className="photovoltaicContainer-kernel__box__right__top__left__name">
                        {mainProduct[1]?.name}
                      </div>
                      <div className="photovoltaicContainer-kernel__box__right__top__left__toDetail">
                        了解更多
                        <ArrowRightOutlined className="top__left__toDetailIcon" />
                      </div>
                      <div className="photovoltaicContainer-kernel__box__right__top__left__toDetailLine"></div>
                      <div
                        className="photovoltaicContainer-kernel__box__right__top__left__content"
                        dangerouslySetInnerHTML={{
                          __html: mainProduct[1]?.content,
                        }}
                      ></div>
                    </div>
                    <div className="photovoltaicContainer-kernel__box__right__top__right">
                      <img src={mainProduct[1]?.img} alt="" loading="lazy" />
                    </div>
                  </div>
                  <div
                    className="photovoltaicContainer-kernel__box__right__foot"
                    onClick={() => {
                      vsf?.navigateTo(
                        `./productSpeDetail?type=${current}&id=${mainProduct[2]?.id}`,
                      );
                    }}
                  >
                    <div className="photovoltaicContainer-kernel__box__right__foot__left">
                      <div className="photovoltaicContainer-kernel__box__right__foot__left__name">
                        {mainProduct[2]?.name}
                      </div>
                      <div className="photovoltaicContainer-kernel__box__right__foot__left__toDetail">
                        了解更多
                      </div>
                      <div className="photovoltaicContainer-kernel__box__right__foot__left__toDetailLine"></div>
                      <div
                        className="photovoltaicContainer-kernel__box__right__foot__left__content"
                        dangerouslySetInnerHTML={{
                          __html: mainProduct[2]?.content,
                        }}
                      ></div>
                    </div>
                    <div className="photovoltaicContainer-kernel__box__right__foot__right">
                      <img src={mainProduct[2]?.img} alt="" loading="lazy" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )} */}

        {allProduct?.length > 0 ? (
          <div className="photovoltaicContainer-product">
            <>
              <div className="photovoltaicContainer-product__title">
                <div className="photovoltaicContainer-product__title__top">
                  All Products
                </div>
                <div className="photovoltaicContainer-product__title__text">
                  {getLabel('suoyouchanpin')}
                </div>
              </div>
              <div className="photovoltaicContainer-product__box">
                {allProduct?.map((item) => {
                  return (
                    <>
                      <div
                        className="photovoltaicContainer-product__box__item"
                        onClick={() => {
                          vsf?.navigateTo(
                            `./productSpeDetail?type=${current}&id=${item?.id}`,
                          );
                        }}
                      >
                        <div className="photovoltaicContainer-product__box__item__top">
                          {item?.isNewProduct && (
                            <>
                              <div className="photovoltaicContainer-product__box__item__top__status">
                                NEW
                              </div>
                            </>
                          )}
                          <div className="photovoltaicContainer-product__box__item__top__img">
                            <img src={item?.img} alt="" loading="lazy" />
                          </div>
                        </div>
                        <div className="photovoltaicContainer-product__box__item__con">
                          <div className="photovoltaicContainer-product__box__item__con__name">
                            {isChinese() ? item?.name : item?.enName}
                          </div>
                          <div
                            className="photovoltaicContainer-product__box__item__con__text"
                            dangerouslySetInnerHTML={{
                              __html: isChinese()
                                ? item?.content
                                : item?.enContent,
                            }}
                          ></div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </>
          </div>
        ) : (
          <div className="empty">
            <div className="empty_img">
              <img src={empty} alt="" />
            </div>
            <div className="empty_title">敬请期待</div>
            <div className="empty_text">请查看其他页面</div>
          </div>
        )}
        {/* {current !== 'ACCESSORIES' && (
          <>
            <div
              className="photovoltaicContainer-newProduct"
              onClick={() => {
                vsf?.navigateTo(
                  `./productSpeDetail?type=${current}&id=${mainProduct[2]?.id}`,
                );
              }}
            >
              <div className="photovoltaicContainer-newProduct__left">
                <img src={latestProduct[0]?.img} alt="" loading="lazy" />
              </div>
              <div className="photovoltaicContainer-newProduct__right">
                <div className="photovoltaicContainer-newProduct__right__title">
                  New Product
                </div>
                <div className="photovoltaicContainer-newProduct__right__text">
                  {latestProduct[0]?.name}
                </div>
                <div
                  className="photovoltaicContainer-newProduct__right__con"
                  dangerouslySetInnerHTML={{
                    __html: latestProduct[0]?.content,
                  }}
                ></div>

                <div className="photovoltaicContainer-newProduct__right__toDetail">
                  了解更多
                  <ArrowRightOutlined className="newProduct__right__toDetail" />
                  <div className="newProduct__right__toDetailLine"></div>
                </div>
              </div>
            </div>
          </>
        )} */}

        {/* {current !== 'ACCESSORIES' && (
          <>
            <div className="photovoltaicContainer-accessories">
              <div className="photovoltaicContainer-accessories__title">
                New Product
              </div>
              <div className="photovoltaicContainer-accessories__text">
                配件
              </div>

              <div className="photovoltaicContainer-accessories__box">
                {relatedAttachment?.map((item) => {
                  return (
                    <>
                      <div
                        className="photovoltaicContainer-accessories__box__item"
                        onClick={() => {
                          vsf?.navigateTo(
                            `./productSpeDetail?type=ACCESSORIES&id=${item?.id}`,
                          );
                        }}
                      >
                        <div className="photovoltaicContainer-accessories__box__item__top">
                          <div className="photovoltaicContainer-accessories__box__item__top__img">
                            <img
                              src={JSON.parse(item?.mainImg)}
                              alt=""
                              loading="lazy"
                            />
                          </div>
                        </div>
                        <div className="photovoltaicContainer-accessories__box__item__con">
                          <div className="photovoltaicContainer-accessories__box__item__con__name">
                            {item?.name}
                          </div>
                          <div className="photovoltaicContainer-accessories__box__item__con__toDetail">
                            了解更多
                            <ArrowRightOutlined className="box__item__con__toDetail" />
                            <div className="box__item__con__toDetailLine"></div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </>
        )} */}

        <div className="photovoltaicContainer-line"></div>
      </div>
    </Section>
  );
};

export default definePage(photovoltaicContainer);
