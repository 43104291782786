import './Product.less';
import vsf, { definePage } from '@vs/vsf-boot';
import { Section } from '@vs/vsf-kit';
import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';

import group from '@/assets/icon/Group.png';
import home from '@/assets/icon/home.png';

import { getImages, getLabel } from '@/config/zhCn_config';

const Product = (props) => {
  const [bitmapImg, setBitmapImg] = useState(getImages('Bitmap'));
  const [bitmap1Img, setBitmap1Img] = useState(getImages('Bitmap1'));
  const [bitmap2Img, setBitmap2Img] = useState(getImages('Bitmap2'));
  const [bitmap3Img, setBitmap3Img] = useState(getImages('Bitmap3'));
  return (
    <Section>
      <div className="productContainer">
        <div className="productContainer-titleImg">
          <img src={getImages('productTItile')} alt="" />
        </div>

        <div className="productContainer-content">
          <div className="productContainer-content__title">
            <img
              src={home}
              alt=""
              className="productContainer-content__title__home"
            />
            <img
              src={group}
              alt=""
              className="productContainer-content__title__group"
            />
            <div className="productContainer-content__title__text">{getLabel('chanpin')}</div>
          </div>
          <div className="productContainer-content__center">
            <div className="productContainer-content__center__left">
              <div className="productContainer-content__center__left__img">
                <img
                  src={bitmapImg}
                  alt=""
                  loading="lazy"
                  onMouseEnter={() => {
                    setBitmapImg(getImages('BitmapScale'));
                  }}
                  onMouseLeave={() => {
                    setBitmapImg(getImages('Bitmap'));
                  }}
                  className="productContainer-content__center__left__top"
                  onClick={() => {
                    vsf?.navigateTo('./photovoltaic');
                  }}
                />
              </div>
              <div className="productContainer-content__center__left__bottom">
                <div className="productContainer-content__center__left__bottom__Left">
                  <img
                    src={bitmap1Img}
                    alt=""
                    loading="lazy"
                    onMouseEnter={() => {
                      setBitmap1Img(getImages('Bitmap1Scale'));
                    }}
                    onMouseLeave={() => {
                      setBitmap1Img(getImages('Bitmap1'));
                    }}
                    onClick={() => {
                      vsf?.navigateTo('./productDetail?type=powerPlant');
                    }}
                  />
                </div>

                <div className="productContainer-content__center__left__bottom__Right">
                  <img
                    src={bitmap2Img}
                    alt=""
                    loading="lazy"
                    onMouseEnter={() => {
                      setBitmap2Img(getImages('Bitmap2Scale'));
                    }}
                    onMouseLeave={() => {
                      setBitmap2Img(getImages('Bitmap2'));
                    }}
                    onClick={() => {
                      vsf?.navigateTo('./productDetail?type=microgrid');
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="productContainer-content__center__right">
              <img
                src={bitmap3Img}
                alt=""
                loading="lazy"
                onMouseEnter={() => {
                  setBitmap3Img(getImages('Bitmap3Scale'));
                }}
                onMouseLeave={() => {
                  setBitmap3Img(getImages('Bitmap3'));
                }}
                className="productContainer-content__center__right__img"
                onClick={() => {
                  vsf?.navigateTo('./productDetail?type=robot');
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default definePage(Product);
