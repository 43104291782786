import './ConnectUs.less';
import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  Form,
  Input,
  Button,
  TextArea,
  Row,
  Col,
  Select,
  message,
} from '@vs/vsf-kit';
import React, { useState, useEffect, useCallback } from 'react';

import group from '@/assets/icon/Group.png';
import home from '@/assets/icon/home.png';
import addressIcon from '@/assets/icon/addressIcon.png';
import globe from '@/assets/icon/globe-01.png';
import mail from '@/assets/icon/mail-02.png';
import phoneIcon from '@/assets/icon/phoneIcon.png';
import Photo from '@/assets/image/Photo.png';
import mesBack from '@/assets/image/Rectangle195.png';

import { getImages, getLabel, isChinese } from '@/config/zhCn_config';

const ConnectUs = (props) => {
  const [form] = Form.useForm();
  const companyInfo = [
    {
      // text: getLabel('dizhi2'),
      icon: addressIcon,
    },
    {
      text: '+86- 19706840835',
      icon: phoneIcon,
    },
    {
      text: 'info@cherrysolution.net',
      icon: mail,
    },
    {
      text: 'www.cherrysolution.net',
      icon: globe,
    },
  ];

  const selectOptions = [
    {
      label: '购买',
      value: 'BUY',
    },
    {
      label: '售后',
      value: 'SALES',
    },
    {
      label: '合作',
      value: 'COOPERATE',
    },
  ];

  const selectOptionsEn = [
    {
      label: 'Purchase product',
      value: 'BUY',
    },
    {
      label: 'After-sales',
      value: 'SALES',
    },
    {
      label: 'Become a Distributor',
      value: 'COOPERATE',
    },
  ];

  const handelSelect = (e) => {
    console.debug(e, '==e==');
  };

  const onFinish = (values) => {
    vsf?.services
      ?.MessageController_createMessage_4501e0?.({
        btoParam: values,
      })
      .then((resposne) => {
        if (resposne && resposne?.code === 200) {
          message.success('提交成功！');
          form?.resetFields();
        }
      });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Section>
      <div className="ConnectUsContainer">
        <div className="ConnectUsContainer-titleImg">
          <img src={getImages('Rectangle192')} alt="" />
        </div>

        <div className="ConnectUsContainer-content">
          <div className="ConnectUsContainer-content__title">
            <img
              src={home}
              alt=""
              className="ConnectUsContainer-content__title__home"
            />
            <img
              src={group}
              alt=""
              className="ConnectUsContainer-content__title__group"
            />
            <div className="ConnectUsContainer-content__title__text">
              {getLabel('lianxiwomen2')}
            </div>
          </div>

          <div className="ConnectUsContainer-content_company">
            <div className="ConnectUsContainer-content_company__left">
              <div className="ConnectUsContainer-content_company__left__title">
                Contact Us
              </div>
              <div className="ConnectUsContainer-content_company__left__text">
                {getLabel('gontsiming')}
              </div>
              <div className="ConnectUsContainer-content_company__left__content">
                {companyInfo?.map((item, index) => {
                  return (
                    <>
                      <div
                        className="ConnectUsContainer-content_company__left__content__item"
                        key={index}
                      >
                        <img
                          src={item?.icon}
                          className="ConnectUsContainer-content_company__left__content__item__icon"
                        />
                        <div className="ConnectUsContainer-content_company__left__content__item__text">
                          {index === 0 ? (
                            isChinese() ? (
                              '浙江省杭州市萧山区宁围街道利一路188号天人大厦'
                            ) : (
                              <>
                                <div>
                                  No.188, Liyi Road, Ningwei Street, Xiaoshan
                                  <br></br>District.Hangzhou, Zhejiang, 311200,
                                  China
                                </div>
                              </>
                            )
                          ) : (
                            item?.text
                          )}
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
            <div className="ConnectUsContainer-content_company__right">
              <img src={Photo} loading="lazy"></img>
            </div>
          </div>
        </div>

        <div className="ConnectUsContainer-bottom">
          <div className="ConnectUsContainer-bottom__left">
            <div className="ConnectUsContainer-bottom__left__title">
              Contact Us
            </div>
            <div className="ConnectUsContainer-bottom__left__text">
              {getLabel('zaixianliuyan')}
            </div>
            <div className="ConnectUsContainer-bottom__left__content">
              {getLabel('womenzhili')}
            </div>
          </div>
          <div className="ConnectUsContainer-bottom__right">
            <Form name="basic" form={form} onFinish={onFinish}>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item
                    name="companyName"
                    rules={
                      isChinese()
                        ? [{ required: true, message: '请输入公司名称!' }]
                        : [
                            {
                              required: true,
                              message: 'Please enter the company name!',
                            },
                          ]
                    }
                  >
                    <Input
                      placeholder={getLabel('gongsiming')}
                      className="customInput"
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    name="mail"
                    rules={
                      isChinese()
                        ? [
                            { required: true, message: '请输入邮箱!' },
                            {
                              pattern: new RegExp(
                                /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
                              ),
                              message: '请检查邮箱格式',
                            },
                          ]
                        : [
                            {
                              required: true,
                              message: 'Please check the email format!',
                            },
                          ]
                    }
                  >
                    <Input
                      placeholder={getLabel('youxiang')}
                      className="customInput"
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    name="name"
                    rules={
                      isChinese()
                        ? [{ required: true, message: '请输入姓名!' }]
                        : [
                            {
                              required: true,
                              message: 'Please enter your name!',
                            },
                          ]
                    }
                  >
                    <Input
                      placeholder={getLabel('xingming')}
                      className="customInput"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="phone"
                    rules={
                      isChinese()
                        ? [{ required: true, message: '请输入电话!' }]
                        : [
                            {
                              required: true,
                              message: 'Please enter phone number!',
                            },
                          ]
                    }
                  >
                    <Input
                      placeholder={getLabel('shouji')}
                      className="customInput"
                      maxLength={11}
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    name="address"
                    rules={
                      isChinese()
                        ? [{ required: true, message: '请输入地址!' }]
                        : [
                            {
                              required: true,
                              message: 'Please enter the address!',
                            },
                          ]
                    }
                  >
                    <Input
                      placeholder={getLabel('dizhi3')}
                      className="customInput"
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    name="messageType"
                    rules={
                      isChinese()
                        ? [{ required: true, message: '请选择类型!' }]
                        : [
                            {
                              required: true,
                              message: 'Please select a type!',
                            },
                          ]
                    }
                  >
                    <Select
                      className="customSelect"
                      placeholder={getLabel('leixing')}
                      dataSource={isChinese() ? selectOptions : selectOptionsEn}
                      onChange={handelSelect}
                    />
                  </Form.Item>
                </Col>

                <Col span={36}>
                  <Form.Item
                    name="content"
                    rules={
                      isChinese()
                        ? [{ required: true, message: '请输入留言内容!' }]
                        : [
                            {
                              required: true,
                              message: 'Please enter the message content!',
                            },
                          ]
                    }
                  >
                    <TextArea
                      placeholder={getLabel('liuyan')}
                      className="customTextArea"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item wrapperCol={{ offset: 9, span: 16 }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    height: '3.875rem',
                  }}
                >
                  {getLabel('tijiao')}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default definePage(ConnectUs);
