import { RouteConfig } from '@vs/vsf-boot';

import Root from '@/layouts';
import HomePage from '@/pages/HomePage';

import pageRoutes from './routes';

const routes: RouteConfig[] = [
  {
    path: '/',
    component: Root,
    redirect: '/HomePage',
    routes: pageRoutes,
  },
];

export default routes;
