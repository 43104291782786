import './projectCase.less';
import vsf, { definePage } from '@vs/vsf-boot';
import { Section, Input, Menu, Checkbox, Space, Pagination } from '@vs/vsf-kit';
import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';

import group from '@/assets/icon/Group.png';
import home from '@/assets/icon/home.png';
import Photo from '@/assets/image/Photo.png';

import { getImages, getLabel, isChinese } from '@/config/zhCn_config';
import { formatDate } from '@/utils/index';

const ProjectCase = (props) => {
  const [pageNum, setPageNum] = useState(0);
  const [pageSize, setPageSize] = useState(6);
  const [total, setTotal] = useState(7);

  const [dataList, setDataList] = useState();

  const loadSDataInfo = () => {
    vsf?.services
      ?.ProductCaseController_getPagedByProductCaseManagementQto_531658?.({
        qto: {
          from: pageNum * pageSize ?? 0,
          size: pageSize ?? 6,
        },
        ext: {},
      })
      .then((resposne) => {
        if (resposne && resposne?.code === 200) {
          setDataList(resposne?.data?.result);
          setTimeout(() => {
            setTotal(resposne?.data?.count);
          }, 1);
        }
      });
  };

  useEffect(() => {
    loadSDataInfo();
  }, [pageNum]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (e) => {
    setPageNum(e - 1);
  };
  return (
    <Section>
      <div className="recruitmentContainer">
        <div
          className="recruitmentContainer-content"
          style={{
            background: 'white',
          }}
        >
          <div className="recruitmentContainer-content__title">
            <img
              src={home}
              alt=""
              className="recruitmentContainer-content__title__home"
            />
            <img
              src={group}
              alt=""
              className="recruitmentContainer-content__title__group"
            />
            <div
              className="newsDetailContainer-content__title__textNav"
              onClick={() => {
                vsf?.navigateTo('./ServiceSupport');
              }}
            >
              {getLabel('fuwuzhichi')}
            </div>
            <img
              src={group}
              alt=""
              className="recruitmentContainer-content__title__group"
            />
            <div className="newsDetailContainer-content__title__textNav">
              {getLabel('xiangmuanliname')}
            </div>
          </div>

          <div className="recruitmentContainer-content__center">
            <div className="recruitmentContainer-content__center__top">
              Project Case
            </div>
            <div className="recruitmentContainer-content__center__flex">
              {getLabel('xiangmuanliname')}
            </div>
          </div>

          <div className="projectCase_box">
            {dataList?.map((item) => {
              return (
                <>
                  <div
                    className="projectCase_box__item"
                    onClick={() => {
                      vsf?.navigateTo(`./projectCaseDetail?id=${item?.id}`);
                    }}
                  >
                    <div className="projectCase_box__item__top">
                      <div className="projectCase_box__item__top__img">
                        <img
                          src={
                            item?.coverImg
                              ? JSON?.parse(item?.coverImg ?? [''])
                              : ''
                          }
                          alt=""
                        />
                      </div>
                      <div className="projectCase_box__item__top__text">
                        <div className="projectCase_box__item__top__text__left">
                          {(isChinese()
                            ? item?.projectType
                            : item?.enProjectType) ?? ''}
                        </div>
                        <div className="projectCase_box__item__top__text__right">
                          {item?.projectDate
                            ? formatDate(item?.projectDate)
                            : ''}
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className="projectCase_box__item__name">
                        {(isChinese() ? item?.name : item?.enName) ?? ''}
                      </div>
                      <div className="projectCase_box__item__detail">
                        {getLabel('chakanxiangqing')}
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>

          <div className="projectCase_Pagination">
            <Pagination
              defaultCurrent={1}
              defaultPageSize={pageSize}
              total={total}
              showSizeChanger={false}
              onChange={handleChange}
              className="my-pagination"
            />
          </div>
        </div>
      </div>
    </Section>
  );
};

export default definePage(ProjectCase);
