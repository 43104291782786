import './productDetail.less';
import vsf, { definePage } from '@vs/vsf-boot';
import { Section } from '@vs/vsf-kit';
import React, { useState, useEffect, useCallback } from 'react';
import group from '@/assets/icon/Group.png';
import home from '@/assets/icon/home.png';
import { getImages, getLabel, isChinese } from '@/config/zhCn_config';
import empty from '@/assets/image/empty.png';

const productDetail = (props) => {
  const type = props?.routes?.query?.type;
  const [dataDetail, setDataDetail] = useState();

  const loadSwiperInfo = () => {
    vsf?.services
      ?.ProductTypeController_getAllByProductTypeQto_6d15e1?.({
        qto: {
          from: 0,
          size: 3,
        },
        ext: {},
      })
      .then((resposne) => {
        if (resposne && resposne?.code === 200) {
          setTimeout(() => {
            if (type === 'powerPlant') {
              const detail = resposne?.data?.find(
                (item) => item?.coreType === 'POWER_PLANT',
              );
              setDataDetail({
                ...detail,
                img: JSON.parse(detail?.img),
                enImg: JSON.parse(detail?.enImg),
              });
            } else if (type === 'microgrid') {
              const detail = resposne?.data?.find(
                (item) => item?.coreType === 'INTELLIGENT_MICRONET',
              );
              setDataDetail({
                ...detail,
                img: JSON.parse(detail?.img),
                enImg: JSON.parse(detail?.enImg),
              });
            } else if (type === 'robot') {
              const detail = resposne?.data?.find(
                (item) => item?.coreType === 'INTELLIGENT_ROBOT',
              );
              setDataDetail({
                ...detail,
                img: JSON.parse(detail?.img),
                enImg: JSON.parse(detail?.enImg),
              });
            }
          }, 1);
        }
      });
  };

  useEffect(() => {
    loadSwiperInfo();
    window.scrollTo(0, 0);
  }, [type]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Section>
      <div className="productDetailContainer">
        <div
          className="productDetailContainer-titleImg"
          style={{
            backgroundImage: `url(${
              type === 'powerPlant'
                ? getImages('powerPlant')
                : type === 'microgrid'
                  ? getImages('microgrid')
                  : type === 'robot'
                    ? getImages('robot')
                    : undefined
            })`,
          }}
        ></div>
        <div className="productDetailContainer-content">
          <div className="newsContainer-content__title">
            <img
              src={home}
              alt=""
              className="newsContainer-content__title__home"
            />
            <img
              src={group}
              alt=""
              className="newsContainer-content__title__group"
            />
            <div
              className="newsDetailContainer-content__title__textNav"
              onClick={() => {
                vsf?.navigateTo('./Product');
              }}
            >
              {getLabel('chanpin')}
            </div>
            <img
              src={group}
              alt=""
              className="newsContainer-content__title__group"
            />
            <div className="newsContainer-content__title__text">
              {type === 'powerPlant'
                ? getLabel('xunidianchang')
                : type === 'microgrid'
                  ? getLabel('zhinengweiwang')
                  : type === 'robot'
                    ? getLabel('zhinengjiqiren')
                    : undefined}
            </div>
          </div>
        </div>

        <div className="productDetailContainer-data">
          {dataDetail?.img?.length > 0 && dataDetail?.enImg?.length > 0 ? (
            <>
              {(isChinese() ? dataDetail?.img : dataDetail?.enImg)?.map(
                (item) => {
                  return (
                    <>
                      <div className="productDetailContainer-data__imgBox">
                        <img
                          src={item}
                          loading="lazy"
                          className="productDetailContainer-data__imgBox__img"
                        />
                      </div>
                    </>
                  );
                },
              )}
            </>
          ) : (
            <>
              <div className="empty">
                <img src={empty} alt="" />
                <div className="empty_title">{getLabel('emptyTitle')}</div>
                <div className="empty_text">{getLabel('emptyText')}</div>
              </div>

              <div className="productDetailContainer-line"></div>
            </>
          )}
        </div>
      </div>
    </Section>
  );
};

export default definePage(productDetail);
