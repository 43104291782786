import vsf from '@vs/vsf-boot';

import address from '@/assets/icon/address.png';
import addressIcon from '@/assets/icon/addressIcon.png';
import one from '@/assets/icon/atom-02.png';
import calanderIcon from '@/assets/icon/calanderIcon.png';
import calculator from '@/assets/icon/calculator.png';
import calculatorFour from '@/assets/icon/calculatorFour.png';
import calculatorIcon from '@/assets/icon/calculatorIcon.png';
import calculatorThree from '@/assets/icon/calculatorThree.png';
import calculatorTwo from '@/assets/icon/calculatorTwo.png';
import downLoad from '@/assets/icon/downLoad.png';
import downLoadIcon from '@/assets/icon/downLoadIcon.png';
import email from '@/assets/icon/email.png';
import globe from '@/assets/icon/globe-01.png';
import group from '@/assets/icon/Group.png';
import three from '@/assets/icon/heart-hand.png';
import home from '@/assets/icon/home.png';
import share from '@/assets/icon/icon_share.png';
import left from '@/assets/icon/left.png';
import leftIcon from '@/assets/icon/leftIcon.png';
import mail from '@/assets/icon/mail-02.png';
import newCalendar from '@/assets/icon/newCalendar.png';
import phone from '@/assets/icon/phone.png';
import phoneIcon from '@/assets/icon/phoneIcon.png';
import right from '@/assets/icon/right.png';
import rightIcon from '@/assets/icon/rightIcon.png';
import robotIcon from '@/assets/icon/robotIcon.png';
import four from '@/assets/icon/shield-tick.png';
import two from '@/assets/icon/sliders-02.png';
import topGroupIcon from '@/assets/icon/topGroupIcon.png';
import WeChat from '@/assets/icon/WeChat.png';
import Bitmap from '@/assets/image/Bitmap.png';
import Bitmap1 from '@/assets/image/Bitmap1.png';
import Bitmap1En from '@/assets/image/Bitmap1En.png';
import Bitmap1Scale from '@/assets/image/Bitmap1Scale.png';
import Bitmap1ScaleEn from '@/assets/image/Bitmap1ScaleEn.png';
import Bitmap2 from '@/assets/image/Bitmap2.png';
import Bitmap2En from '@/assets/image/Bitmap2En.png';
import Bitmap2Scale from '@/assets/image/Bitmap2Scale.png';
import Bitmap2ScaleEn from '@/assets/image/Bitmap2ScaleEn.png';
import Bitmap3 from '@/assets/image/Bitmap3.png';
import Bitmap3En from '@/assets/image/Bitmap3En.png';
import Bitmap3Scale from '@/assets/image/Bitmap3Scale.png';
import Bitmap3ScaleEn from '@/assets/image/Bitmap3ScaleEn.png';
import BitmapEn from '@/assets/image/BitmapEn.png';
import BitmapScale from '@/assets/image/BitmapScale.png';
import BitmapScaleEn from '@/assets/image/BitmapScaleEn.png';
import bottomPhoto from '@/assets/image/bottomPhoto.png';
import bottomPhotoEn from '@/assets/image/bottomPhotoEn.png';
import empty from '@/assets/image/empty.png';
import fourImg from '@/assets/image/fourImg.png';
import homeBanner from '@/assets/image/home.png';
import homeBannerEn from '@/assets/image/homeEn.png';
import homeSwiperpng from '@/assets/image/homeSwiperpng.png';
import logo from '@/assets/image/logo.png';
import microgrid from '@/assets/image/microgrid.png';
import microgridEn from '@/assets/image/microgridEn.png';
import newImg from '@/assets/image/news.png';
import newsTitleImg from '@/assets/image/newsTitleImg.png';
import newsTitleImgEn from '@/assets/image/newsTitleImgEn.png';
import oneImg from '@/assets/image/oneImg.png';
import Photo from '@/assets/image/Photo.png';
import photovoltaicTitle from '@/assets/image/photovoltaicTitle.png';
import photovoltaicTitleEn from '@/assets/image/photovoltaicTitleEn.png';
import powerPlant from '@/assets/image/powerPlant.png';
import powerPlantEn from '@/assets/image/powerPlantEn.png';
import bottomImg from '@/assets/image/productDetailBottom.png';
import productDetailBottomEn from '@/assets/image/productDetailBottomEn.png';
import productOneScale from '@/assets/image/productOneScale.png';
import productOneScaleEn from '@/assets/image/productOneScaleEn.png';
import productScale from '@/assets/image/productScale.png';
import productScaleEn from '@/assets/image/productScaleEn.png';
import productThreeScale from '@/assets/image/productThreeScale.png';
import productThreeScaleEn from '@/assets/image/productThreeScaleEn.png';
import productTItile from '@/assets/image/productTItile.png';
import productTItileEn from '@/assets/image/productTItileEn.png';
import productTwoScale from '@/assets/image/productTwoScale.png';
import productTwoScaleEn from '@/assets/image/productTwoScaleEn.png';
import RecruitmentTitleImg from '@/assets/image/RecruitmentTitleImg.png';
import RecruitmentTitleImgEn from '@/assets/image/RecruitmentTitleImgEn.png';
import company from '@/assets/image/Rectangle182.png';
import Rectangle192 from '@/assets/image/Rectangle192.png';
import Rectangle192En from '@/assets/image/Rectangle192En.png';
import mesBack from '@/assets/image/Rectangle195.png';
import Rectangle1097 from '@/assets/image/Rectangle1097.png';
import Rectangle1098 from '@/assets/image/Rectangle1098.png';
import Rectangle1099 from '@/assets/image/Rectangle1099.png';
import robot from '@/assets/image/robot.png';
import robotEn from '@/assets/image/robotEn.png';
import supportTItile from '@/assets/image/supportTitle.png';
import supportTItileEn from '@/assets/image/supportTitleEn.png';
import threeImg from '@/assets/image/threeImg.png';
import twoImg from '@/assets/image/twoImg.png';
import weChatCode from '@/assets/image/weChatCode.jpg';

export const isChinese = () => {
  return vsf?.stores?.zhCn?.language === 'chinese';
};

export const getImages = (key) => {
  const imageObject = {
    Bitmap: isChinese() ? Bitmap : BitmapEn,
    Bitmap1: isChinese() ? Bitmap1 : Bitmap1En,
    Bitmap1Scale: isChinese() ? Bitmap1Scale : Bitmap1ScaleEn,
    Bitmap2: isChinese() ? Bitmap2 : Bitmap2En,
    Bitmap2Scale: isChinese() ? Bitmap2Scale : Bitmap2ScaleEn,
    Bitmap3: isChinese() ? Bitmap3 : Bitmap3En,
    Bitmap3Scale: isChinese() ? Bitmap3Scale : Bitmap3ScaleEn,
    BitmapScale: isChinese() ? BitmapScale : BitmapScaleEn,
    bottomPhoto: isChinese() ? bottomPhoto : bottomPhotoEn,
    newsTitleImg: isChinese() ? newsTitleImg : newsTitleImgEn,
    photovoltaicTitle: isChinese() ? photovoltaicTitle : photovoltaicTitleEn,
    powerPlant: isChinese() ? powerPlant : powerPlantEn,
    productOneScale: isChinese() ? productOneScale : productOneScaleEn,
    productScale: isChinese() ? productScale : productScaleEn,
    productThreeScale: isChinese() ? productThreeScale : productThreeScaleEn,
    productTItile: isChinese() ? productTItile : productTItileEn,
    supportTItile: isChinese() ? supportTItile : supportTItileEn,
    productTwoScale: isChinese() ? productTwoScale : productTwoScaleEn,
    RecruitmentTitleImg: isChinese()
      ? RecruitmentTitleImg
      : RecruitmentTitleImgEn,
    Rectangle192: isChinese() ? Rectangle192 : Rectangle192En,
    robot: isChinese() ? robot : robotEn,
    microgrid: isChinese() ? microgrid : microgridEn,
    bottomImg: isChinese() ? bottomImg : productDetailBottomEn,
    homeBanner: isChinese() ? homeBanner : homeBannerEn,
  };
  return imageObject?.[key];
};

export const getLabel = (key) => {
  const labelObject = {
    productLabel: isChinese() ? '产品' : 'PRODUCTS',
    photovoltaic: isChinese() ? '产品中心' : 'Product List',
    robot: isChinese() ? '智能机器人' : 'Intelligent Robot',
    microgrid: isChinese() ? '虚拟电厂' : 'Virtual Power Plant',
    powerPlant: isChinese() ? '智能微网' : 'Smart Grid',
    miniInverter: isChinese() ? '微型逆变器' : 'Microinverter',
    // outdoorInverter: isChinese() ? '户用光伏逆变器' : 'Smart Grid',
    outdoorInverter: isChinese() ? '户用光储' : 'Residential',
    newsText: isChinese() ? '新闻资讯' : 'News',
    emptyTitle: isChinese() ? '敬请期待' : 'No information',
    emptyText: isChinese() ? '请查看其他页面' : 'Please check the other pages',
    // commerceInverter: isChinese()
    //   ? '工商业光伏逆变器'
    //   : 'Commercial & Industrial PCS',
    commerceInverter: isChinese() ? '工商业储能' : 'Commercial & Industrial',
    // attachment: isChinese() ? '配件' : 'Accessories',
    attachment: isChinese() ? '其他产品' : 'Accessories',
    lingtanweilai: isChinese()
      ? '零碳未来 全球共享'
      : 'Zero Carbon future for world',
    lingtanweilaiContent: isChinese()
      ? '让绿色能源触手可及，让零碳未来不再遥远'
      : 'Green Energy Accessible, Zero Carbon Future Attainable',
    tiyanchuangxin: isChinese()
      ? '体验我们的创新'
      : 'Experience our innovations',
    lingtanzhilu: isChinese()
      ? '零碳之路，不仅是一个梦想 \n更是我们共同的使命'
      : 'From Dream to Mission,\nBridging Your Energy Future',
    gongtongshiming: isChinese()
      ? '更是我们共同的使命'
      : 'Zero Carbon Journey, \nBeyond a Dream, Our Collective Mission',
    wulinninshi: isChinese()
      ? '无论您是寻求节能减排，还是追求能源自主，无论是光储技术的实际运用，还是智能微网的大胆构想，桥跃智能都热衷于为您提供量身定制的能源解决方案，为您的家庭和企业创造可靠的能源未来。'
      : `Whether you're looking for energy conservation or striving for energy autonomy, whether it's the practical implementation of PV and ESS technology or the creative conceptualization of intelligent microgrids, Cherry is enthusiastic about offering you customized energy solutions, creating a reliable energy future for your homes and businesses.`,
    kejiqudong: isChinese() ? '科技驱动先锋' : 'Leading Technology',
    liyongxianjin: isChinese()
      ? '利用先进技术，推动光储领域的发展'
      : 'Advancing the PV and ESS product\nwith Innovative Technologies',
    duoyuanchanpin: isChinese() ? '多元产品矩阵' : 'Diverse Array',
    tigongduoyang: isChinese()
      ? '提供多样化的产品选择，满足不同需求'
      : 'Offering diverse product selections to\nmeet varied demands',
    zonghejiejue: isChinese() ? '综合解决方案' : 'Comprehensive Solutions',
    zhengheziyuan: isChinese()
      ? '整合各类资源，为用户提供一站式服务'
      : 'Integrating various resources to provide\nclients with One-Stop services',
    quanqiubuju: isChinese() ? '全球布局战略' : 'Global Distribution',
    zhuoyanquanqiu: isChinese()
      ? '着眼全球市场，进行本地化战略布局'
      : 'Global Market Vision, Carrying Out\nLocalization Strategic Planning',
    womende: isChinese() ? '我们的' : 'Our Core',
    hexinchanpin: isChinese() ? '核心产品' : 'Products',
    xinwendongtai: isChinese() ? '新闻动态' : 'Our Latest News',
    jiaruqiaoyue: isChinese()
      ? '加入桥跃，追梦路上一起前行'
      : 'Cherry invites you to pursue your dreams with us!',
    jiaruwomen: isChinese() ? '加入我们' : 'Join us',
    dianhua: isChinese() ? '电话' : 'Call Us',
    dizhi: isChinese() ? '地址' : 'Address',
    address: isChinese()
      ? '浙江省杭州市萧山区宁围街道利一路188号天人大厦'
      : 'Tianren Building No.188, Liyi Road, Ningwei Street,\nXiaoshan District.Hangzhou, Zhejiang, China',
    guanyuwomen: isChinese() ? '关于桥跃' : 'About',
    gonsiyuanjing: isChinese() ? '公司简介' : 'Company Profile',
    gongsijianjie: isChinese() ? '联系我们' : 'Contact',
    chanpin: isChinese() ? '产品' : 'Products',
    fuwuzhichi: isChinese() ? '服务支持' : 'Support',
    guangfunibianqi: isChinese() ? '产品中心' : 'Product List',
    xunidianchang: isChinese() ? '虚拟电厂' : 'Virtual Power Plant',
    zhinengweiwang: isChinese() ? '智能微网' : 'Smart Grid',
    zhinengjiqiren: isChinese() ? '智能机器人' : 'Intelligent Robot',
    zhaoxiannashi: isChinese() ? '招贤纳士' : 'Join us',
    rezhaozhiwei: isChinese() ? '热招职位' : 'Open Positions',
    zaizhaozhiwei: isChinese() ? '在招职位' : 'OPEN POSITIONS',
    lianxiwomen: isChinese() ? '联系我们' : 'Contact',
    changyonggongneng: isChinese() ? '常用功能' : 'Help Center',
    xiazaizhongxin: isChinese() ? '下载中心' : 'Download',
    shipinzhongxin: isChinese() ? '视频中心' : 'Video',
    changjianwenti: isChinese() ? '常见问题' : 'FAQs',
    changjianwentiname: isChinese() ? '常见问题' : 'FAQ',
    xiangmuanli: isChinese() ? '项目案例' : 'Cases',

    xiazaizhongxinname: isChinese() ? '下载中心' : 'Download Center',
    shipinzhongxinname: isChinese() ? '视频中心' : 'Video Center',

    xiangmuanliname: isChinese() ? '项目案例' : 'Case Studies',
    banquan: isChinese()
      ? '版权所有 © 2024 桥跃智能科技（杭州）有限公司'
      : 'Copyright © 2024 Cherry Solution Co., Ltd.',
    guanfanggongzhonghao: isChinese() ? '官方公众号' : 'Wechat',
    lingtanweilai2: isChinese() ? '桥跃智造@' : 'Cherry on Top',
    quanqiugongxiang: isChinese() ? '零碳生活' : '@smart zero-carbon',
    ranglvse: isChinese() ? '让绿色能源触手可及' : 'Green Energy Accessible, ',
    ranglingtan: isChinese()
      ? '让零碳未来不再遥远'
      : 'Zero-Carbon Future Attainable ',
    qiaoyuezhineng: isChinese() ? '' : 'Cherry Solution Co.,Ltd.',
    gongsizizhuan: isChinese()
      ? '桥跃智能成立于2023年，是一家专注于新一代绿色能源光储系统、虚拟电厂及微网系统开发的技术驱动型公司。公司核心团队均来自行业龙头企业及国内外一流大学，着力于新型电力电子数字控制技术，新型第三代半导体器件封装技术，新型能源数字化智能化技术等，致力于为全球绿色能源发展提供一流解决方案。'
      : 'Cherry established in 2023, is a technology-driven company specializing in the development of advancing photovoltaic inverters, energy storage systems, virtual power plants, and microgrid systems. The core team comprises professionals from leading industry enterprises and top-tier universities both domestically and internationally. The company focuses on innovative power electronic digital control technology, new packaging techniques for third-generation semiconductor devices, and digitalization and smart technology for new energy. We are dedicated to providing innovative solutions for global green energy development.',
    suoyouchanpin: isChinese() ? '所有产品' : 'Product list',
    chanpincanshu: isChinese() ? '产品参数' : 'Parameters',
    jishucanshu: isChinese() ? '技术参数' : 'specification',
    renzhengzhengshu: isChinese() ? '认证证书' : 'certification',
    yonghushouce: isChinese() ? '用户手册' : 'user manual',
    xiangguanchanpin: isChinese() ? '相关产品' : 'Related Products',
    xinweizixun: isChinese() ? '新闻资讯' : 'News',
    xinwendongtai2: isChinese() ? '新闻动态' : 'Our\nLatest News',
    gongsixinwen: isChinese() ? '公司新闻' : 'Company News',
    hangyedongtai: isChinese() ? '行业动态' : 'Industry News',
    chakanxiangqing: isChinese() ? '查看详情' : 'View',
    lianxiwomen2: isChinese() ? '联系我们' : 'CONTACT',
    gontsiming: isChinese()
      ? '桥跃智能科技（杭州）有限公司'
      : 'CHERRY SOLUTION CO.,LTD.',
    dizhi2: isChinese()
      ? '浙江省杭州市萧山区宁围街道利一路188号天人大厦10层1005室'
      : 'Room 1005,No.188, Liyi Road, Ningwei Street, Xiaoshan\nDistrict.Hangzhou, Zhejiang, 311200, China',
    zaixianliuyan: isChinese() ? '在线留言' : 'Message',
    womenzhili: isChinese()
      ? '我们致力于为世界各地的客户和合作伙伴提供最优质的服务。'
      : 'We are committed to providing the highest quality service to our customers and partners around the world.',
    gongsiming: isChinese() ? '公司名称*' : 'Company Name*',
    youxiang: isChinese() ? '邮箱*' : 'Email*',
    xingming: isChinese() ? '姓名*' : 'Name*',
    shouji: isChinese() ? '电话*' : 'Phone*',
    dizhi3: isChinese() ? '地址*' : 'Address*',
    leixing: isChinese() ? '类型*' : 'Purchase product*',
    liuyan: isChinese() ? '留言内容*' : 'Comment or Message*',
    tijiao: isChinese() ? '提交' : 'Submit',

    dizhi5: isChinese() ? '地址' : 'LOCATION',
    xmlx: isChinese() ? '项目类型' : 'PROJECE TYPE',
    zjrl: isChinese() ? '装机容量' : 'SOLAR SYSTEM SIZE',

    cplx: isChinese() ? '产品类型' : 'PRODUCTS',
    zwnr: isChinese() ? '暂无内容' : 'NO CONTENT',
    ckqtym: isChinese() ? '请查看其他页面' : 'Please check the other pages',
  };
  return labelObject?.[key];
};
