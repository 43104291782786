import './RecruitmentDetail.less';
import vsf, { definePage } from '@vs/vsf-boot';
import { Section, message } from '@vs/vsf-kit';
import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';

import group from '@/assets/icon/Group.png';
import share from '@/assets/icon/icon_share.png';
import dayjs from 'dayjs';

const RecruitmentDetail = (props) => {
  const id = props?.routes?.query?.id;
  const [detail, setDetail] = useState({});
  const loadSDataInfo = () => {
    vsf?.services
      ?.RecruitmentController_getRecruitmentVoById_d7e03c?.({
        id: id,
      })
      .then((resposne) => {
        if (resposne && resposne?.code === 200) {
          setDetail(resposne?.data);
        }
      });
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(window?.location?.href);
      message?.success('已复制该链接，请分享');
    } catch (err) {
      message?.warn('分享失败');
    }
  };

  useEffect(() => {
    loadSDataInfo();
    window.scrollTo(0, 0);
  }, []);
  return (
    <Section>
      <div className="RecruitmentDetailContainer">
        <div className="RecruitmentDetailContainer__title">
          <div
            style={{ cursor: 'pointer', width: '9.375rem' }}
            onClick={() => {
              vsf?.navigateTo('./Recruitment');
            }}
          >
            返回职位列表
          </div>
        </div>
        <div className="RecruitmentDetailContainer__content">
          <div className="RecruitmentDetailContainer__content__center">
            <div className="RecruitmentDetailContainer__content__center__title">
              <div className="RecruitmentDetailContainer__content__center__title__top">
                <div className="RecruitmentDetailContainer__content__center__title__top__left">
                  {detail?.isUrgent && (
                    <>
                      <div className="isUrgent">急</div>
                    </>
                  )}
                  <div className="positionName">{detail?.position}</div>
                </div>
                <div
                  className="RecruitmentDetailContainer__content__center__title__top__right"
                  onClick={handleCopy}
                >
                  <img
                    src={share}
                    alt=""
                    className="shareIcon"
                    loading="lazy"
                  />
                  <div className="share">分享</div>
                </div>
              </div>
              <div className="RecruitmentDetailContainer__content__center__title__foot">
                <div>
                  <div className="department">{detail?.department}</div>
                  <div className="pubTime">
                    发布于{dayjs(detail?.pubTime).format('YYYY-MM-DD') ?? '-'}
                  </div>
                </div>
                <div></div>
              </div>
            </div>
            <div
              className="RecruitmentDetailContainer__content__center__con"
              dangerouslySetInnerHTML={{ __html: detail?.content }}
            ></div>

            {/* <div className="RecruitmentDetailContainer__content__center__bottom">
              <div className="RecruitmentDetailContainer__content__center__bottom__text">
                职位信息
              </div>
              <div className="RecruitmentDetailContainer__content__center__bottom__box">
                <div className="RecruitmentDetailContainer__content__center__bottom__box__item">
                  <div>发布日期</div>
                  <div>{detail?.pubTime}</div>
                </div>
                <div className="RecruitmentDetailContainer__content__center__bottom__box__item">
                  <div>所属部门</div>
                  <div>{detail?.department}</div>
                </div>
                <div className="RecruitmentDetailContainer__content__center__bottom__box__item">
                  <div>职位名称</div>
                  <div>{detail?.position}</div>
                </div>
              </div>
            </div> */}
          </div>

          <div
            className="RecruitmentDetailContainer__content__btn"
            onClick={() => {
              window.open(detail?.link);
            }}
          >
            申请职位
          </div>
        </div>
      </div>
    </Section>
  );
};

export default definePage(RecruitmentDetail);
