import './Recruitment.less';
import vsf, { definePage } from '@vs/vsf-boot';
import { Section, Pagination, Table } from '@vs/vsf-kit';
import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import group from '@/assets/icon/Group.png';
import home from '@/assets/icon/home.png';
import dayjs from 'dayjs';

import { getImages, getLabel } from '@/config/zhCn_config';

const Recruitment = (props) => {
  const [dataSource, setDataSource] = useState([]);
  const [pageNum, setPageNum] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(10);
  const loadSDataInfo = () => {
    vsf?.services
      ?.RecruitmentController_getPagedByRecruitmentQto_426d6a?.({
        qto: {
          from: pageNum * pageSize ?? 0,
          size: pageSize ?? 10,
        },
        ext: {},
      })
      .then((resposne) => {
        if (resposne && resposne?.code === 200) {
          setDataSource(resposne?.data?.result);
          setTimeout(() => {
            setTotal(resposne?.data?.count);
          }, 1);
        }
      });
  };

  const handleChange = (e) => {
    setPageNum(e - 1);
  };

  useEffect(() => {
    loadSDataInfo();
  }, [pageNum]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const columns = [
    {
      title: '职位',
      dataIndex: 'position',
      key: 'position',
      render: (text, param, index) => (
        <div
          style={{
            display: 'flex',
          }}
        >
          <div
            className="name"
            onClick={(e) => {
              vsf?.navigateTo(`./RecruitmentDetail?id=${param?.id}`);
            }}
          >
            {text}
          </div>
          {param?.isUrgent && (
            <>
              <div className="isUrgentText">
                <div className="isUrgentText__icon"></div>
                <div className="isUrgentText__text">加急</div>
              </div>
            </>
          )}
        </div>
      ),
    },
    {
      title: '部门',
      dataIndex: 'department',
      key: 'department',
      render: (text, param, index) => (
        <div
          className="tableItem"
          onClick={(e) => {
            vsf?.navigateTo(`./RecruitmentDetail?id=${param?.id}`);
          }}
        >
          {text}
        </div>
      ),
    },

    {
      title: '工作地点',
      dataIndex: 'workPlace',
      key: 'workPlace',
      render: (text, param, index) => (
        <div
          className="tableItem"
          onClick={(e) => {
            vsf?.navigateTo(`./RecruitmentDetail?id=${param?.id}`);
          }}
        >
          {text}
        </div>
      ),
    },
    {
      title: '发布时间',
      dataIndex: 'pubTime',
      key: 'pubTime',
      render: (text, param, index) => (
        <div
          className="tableItem"
          onClick={(e) => {
            vsf?.navigateTo(`./RecruitmentDetail?id=${param?.id}`);
          }}
        >
          {dayjs(text).format('YYYY-MM-DD') ?? ''}
        </div>
      ),
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      render: (text, param, index) => (
        <div
          className="action"
          onClick={(e) => {
            window.open(param?.link);
          }}
        >
          申请职位
        </div>
      ),
    },
  ];

  return (
    <Section>
      <div className="recruitmentContainer">
        <div className="recruitmentContainer-titleImg">
          <img src={getImages('RecruitmentTitleImg')} alt="" />
        </div>
        <div className="recruitmentContainer-content">
          <div className="recruitmentContainer-content__title">
            <img
              src={home}
              alt=""
              className="recruitmentContainer-content__title__home"
            />
            <img
              src={group}
              alt=""
              className="recruitmentContainer-content__title__group"
            />
            <div className="recruitmentContainer-content__title__text">
              {getLabel('zhaoxiannashi')}
            </div>
          </div>

          <div className="recruitmentContainer-content__center">
            <div className="recruitmentContainer-content__center__top">
              Join Us
            </div>
            <div className="recruitmentContainer-content__center__flex">
              {getLabel('zaizhaozhiwei')}
            </div>
          </div>

          <div className="recruitmentContainer-content__foot">
            <div className="recruitmentContainer-content__foot__table">
              <Table
                className="customTable"
                dataSource={dataSource}
                columns={columns}
                pagination={false}
              />
            </div>
            <div className="recruitmentContainer-content__foot__Pagination">
              <Pagination
                defaultCurrent={pageNum === 0 ? 1 : pageNum}
                defaultPageSize={pageSize}
                total={total}
                showSizeChanger={false}
                onChange={handleChange}
                className="my-pagination"
              />
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default definePage(Recruitment);
