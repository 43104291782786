import './downLoadCenter.less';
import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  Input,
  Menu,
  Checkbox,
  Space,
  Pagination,
  Image,
  Radio,
} from '@vs/vsf-kit';
import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';

import group from '@/assets/icon/Group.png';
import home from '@/assets/icon/home.png';
import { DownOutlined } from '@ant-design/icons';
import empty from '@/assets/image/empty.png';
import downIcon from '@/assets/icon/downIcon.png';
import search from '@/assets/icon/search.png';

import { getImages, getLabel, isChinese } from '@/config/zhCn_config';
import { cloneDeep } from 'lodash';

const DownLoadCenter = (props) => {
  const [pageNum, setPageNum] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(12);
  const [dataList, setDataList] = useState();
  const [inputValue, setInputValue] = useState();
  const [slectKey, setSlectKey] = useState(['MINI_INVERTER', 'PRODUCT_MANUAL']);

  const subMenuItem = [
    {
      name: '微型逆变器',
      enName: 'Microinverter',
      value: 'MINI_INVERTER',
      checked: false,
    },
    {
      name: '户用储能',
      value: 'OUTDOOR_INVERTER',
      enName: ' Hybrid Inverter',
      checked: false,
    },
    {
      name: '工商业储能',
      checked: false,
      value: 'COMMERCE_INVERTER',
      enName: 'Commercial & Industrial PCS',
    },
    {
      name: '智能云平台',
      checked: false,
      value: 'INTELLIGENT_CLOUD',
      enName: 'Smart Energy Platform',
    },
    {
      name: '配件',
      checked: false,
      value: 'ATTACHMENT',
      enName: 'Accessories',
    },
  ];

  const subMenuList = [
    {
      name: '产品手册',
      enName: 'Datasheet',
      key: 'PRODUCT_MANUAL',
      children: subMenuItem,
    },
    {
      name: '用户手册',
      key: 'USER_MANUAL',
      enName: 'User Manual',
      children: subMenuItem,
    },
    {
      name: '快安指南',
      key: 'FAST_MANUAL',
      enName: 'Quick Guide',
      children: subMenuItem,
    },
    {
      name: '认证证书',
      key: 'CERTIFICATION_MANUAL',
      enName: 'Certificate',
      children: subMenuItem,
    },
  ];

  const handleChange = (e) => {
    setPageNum(e - 1);
  };

  const loadSDataInfo = () => {
    vsf?.services
      ?.DocumentCenterFileController_getPagedByDocumentCenterQto_06fbf6?.({
        qto: {
          from: pageNum * pageSize ?? 0,
          size: pageSize ?? 10,
          firstLevelTypeIs: slectKey?.[1],
          secondLevelTypeIs: slectKey?.[0],
          fileNameLike: isChinese() ? inputValue : undefined,
          enFileNameLike: isChinese() ? undefined : inputValue,
        },
        ext: {},
      })
      .then((resposne) => {
        if (resposne && resposne?.code === 200) {
          setDataList(resposne?.data?.result);
          setTimeout(() => {
            setTotal(resposne?.data?.count);
          }, 1);
        }
      });
  };
  useEffect(() => {
    loadSDataInfo();
  }, [pageNum, inputValue, slectKey]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onchangeValue = (e) => {
    setInputValue(e?.target?.value);
  };
  return (
    <Section>
      <div className="recruitmentContainer">
        <div
          className="recruitmentContainer-content"
          style={{
            background: 'white',
          }}
        >
          <div className="recruitmentContainer-content__title">
            <img
              src={home}
              alt=""
              className="recruitmentContainer-content__title__home"
            />
            <img
              src={group}
              alt=""
              className="recruitmentContainer-content__title__group"
            />
            <div
              className="newsDetailContainer-content__title__textNav"
              onClick={() => {
                vsf?.navigateTo('./ServiceSupport');
              }}
            >
              {getLabel('fuwuzhichi')}
            </div>
            <img
              src={group}
              alt=""
              className="recruitmentContainer-content__title__group"
            />
            <div className="recruitmentContainer-content__title__text">
              {getLabel('xiazaizhongxinname')}
            </div>
          </div>

          <div className="recruitmentContainer-content__center">
            <div className="recruitmentContainer-content__center__top">
              DownLoad
            </div>
            <div className="recruitmentContainer-content__center__flex">
              {getLabel('xiazaizhongxinname')}
            </div>

            <div className="downLoadCenter__box">
              <div>
                <Input
                  placeholder={
                    (isChinese()
                      ? '输入你想要搜索的关键词，如：微型逆变器'
                      : '  Start typing to search...') ?? ''
                  }
                  prefix={
                    <Image
                      src={search}
                      style={{
                        width: '1.1rem',
                        height: '1.2rem',
                        marginTop: '-0.125rem',
                      }}
                    ></Image>
                  }
                  style={{
                    width: '60rem',
                    height: '2.5rem',
                    borderRadius: '0.375rem',
                  }}
                  allowClear
                  onPressEnter={(e) => {
                    onchangeValue(e);
                  }}
                  onChange={(e) => {
                    if (!e?.target?.value) {
                      setInputValue('');
                    }
                  }}
                />
              </div>

              <div className="downLoadCenter__box__contanier">
                <div className="downLoadCenter__box__contanier__left">
                  <Menu mode="inline" defaultOpenKeys={['PRODUCT_MANUAL']}>
                    {subMenuList?.map((item) => {
                      return (
                        <>
                          <Menu.SubMenu
                            key={item?.key}
                            title={
                              (isChinese() ? item?.name : item?.enName) ?? ''
                            }
                            onClick={(e) => {
                              setSlectKey(e?.keyPath);
                            }}
                          >
                            {item?.children?.map((ele) => {
                              return (
                                <>
                                  <Menu.Item key={ele?.value}>
                                    <Checkbox
                                      checked={
                                        item?.key === slectKey?.[1] &&
                                        ele?.value === slectKey?.[0]
                                          ? true
                                          : false
                                      }
                                    />

                                    <span
                                      style={{
                                        marginLeft: '1rem',
                                        fontSize: '0.875rem',
                                      }}
                                    >
                                      {(isChinese()
                                        ? ele?.name
                                        : ele?.enName) ?? ''}
                                    </span>
                                  </Menu.Item>
                                </>
                              );
                            })}
                          </Menu.SubMenu>
                        </>
                      );
                    })}
                  </Menu>
                </div>

                <div className="downLoadCenter__box__contanier__right">
                  <div>
                    {dataList?.length > 0 ? (
                      <>
                        {dataList?.map((item) => {
                          return (
                            <>
                              <div className="downLoadCenter__box__contanier__right__table">
                                <div
                                  style={{
                                    display: 'flex',
                                  }}
                                >
                                  <div className="downLoadCenter__box__contanier__right__table__icon">
                                    <img src={downIcon} alt="" />
                                  </div>
                                  <div className="downLoadCenter__box__contanier__right__table__left">
                                    {(isChinese()
                                      ? item?.fileName
                                      : item?.enFileName) ?? ''}
                                  </div>
                                </div>
                                <div
                                  className="downLoadCenter__box__contanier__right__table__right"
                                  onClick={() => {
                                    const a = document.createElement('a');
                                    a.href = JSON.parse(item?.fileUrl);
                                    a.download =
                                      (isChinese()
                                        ? `${item?.fileName}.pdf`
                                        : `${item?.enFileName}.pdf`) ?? '';
                                    a.style.display = 'none';
                                    document.body.appendChild(a);
                                    a.click();
                                    document.body.removeChild(a);
                                  }}
                                >
                                  {isChinese() ? '下载' : 'DownLoad'}
                                </div>
                              </div>
                            </>
                          );
                        })}

                        <div className="newsContainer-content__center__Pagination">
                          <Pagination
                            defaultCurrent={1}
                            defaultPageSize={pageSize}
                            total={total}
                            showSizeChanger={false}
                            onChange={handleChange}
                            className="my-pagination"
                          />
                        </div>
                      </>
                    ) : (
                      <div className="empty">
                        <div className="empty_img">
                          <img src={empty} alt="" />
                        </div>
                        <div className="empty_title">{getLabel('zwnr')}</div>
                        <div className="empty_text">{getLabel('ckqtym')}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default definePage(DownLoadCenter);
