import React, { useEffect } from 'react';
// source from https://www.npmjs.com/package/use-force-update
export function useForceUpdate(): () => void {
  const [, dispatch] = React.useState<Record<string, any>>(Object.create(null));

  // Turn dispatch(required_parameter) into dispatch().
  const memoizedDispatch = React.useCallback((): void => {
    dispatch(Object.create(null));
  }, [dispatch]);
  return memoizedDispatch;
}

export const useHorizontalScroll = (ref) => {
  useEffect(() => {
    const handleWheel = (event) => {
      if (ref) {
        ref.current.scrollLeft += event.deltaY;
      }
    };

    ref?.current?.addEventListener('wheel', handleWheel);
  }, [ref]);
};

export const getMonthName = (dateString) => {
  const monthNames = [
    '一月',
    '二月',
    '三月',
    '四月',
    '五月',
    '六月',
    '七月',
    '八月',
    '九月',
    '十月',
    '十一月',
    '十二月',
  ];
  const monthNamesEn = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const dateParts = dateString.split('-');
  const monthIndex = parseInt(dateParts[1], 10) - 1;
  const day = parseInt(dateParts[2], 10); // 获取日
  return {
    day: day,
    chineseMonth: monthNames[monthIndex],
    englishMonth: monthNamesEn[monthIndex],
  };
};
export const formatDate = (dateStr) => {
  const date = new Date(dateStr);

  if (isNaN(date.getTime())) {
    throw new Error('Invalid date string');
  }

  const month = String(date.getMonth() + 1).padStart(2, '0');

  const day = String(date.getDate()).padStart(2, '0');

  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
};

export const isMp4Link = (url) => {
  const regex = /\.mp4$/i;
  return regex.test(url);
};
