import './News.less';
import vsf, { definePage } from '@vs/vsf-boot';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Section, Pagination } from '@vs/vsf-kit';
import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import group from '@/assets/icon/Group.png';
import home from '@/assets/icon/home.png';
import { getMonthName } from '@/utils/index';
import calanderIcon from '@/assets/icon/calanderIcon.png';
import storage from '@/utils/storage';
import { getImages, getLabel, isChinese } from '@/config/zhCn_config';

const News = (props) => {
  const [informationTypeIs, setInformationTypeIs] = useState('COMPANY');

  const [newsList, setNewsList] = useState([]);
  const [pageNum, setPageNum] = useState(0);
  const [pageSize, setPageSize] = useState(3);
  const [total, setTotal] = useState(4);
  const loadSDataInfo = () => {
    vsf?.services
      ?.InformationManagementController_getPagedByInformationInfoQto_a268b7?.({
        qto: {
          from: pageNum * pageSize ?? 0,
          size: pageSize ?? 3,
          informationTypeIs: informationTypeIs,
        },
        ext: {},
      })
      .then((resposne) => {
        if (resposne && resposne?.code === 200) {
          const date = resposne?.data?.result?.map((item) => {
            return {
              ...item,
              dateString: item?.pubTime
                ? getMonthName(item?.pubTime ?? '')
                : '',
            };
          });
          setNewsList(date);
          setTimeout(() => {
            setTotal(resposne?.data?.count);
          }, 1);
        }
      });
  };
  const handleChange = (e) => {
    setPageNum(e - 1);
  };
  useEffect(() => {
    loadSDataInfo();
  }, [informationTypeIs, pageNum]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Section>
      <div className="newsContainer">
        <div className="newsContainer-titleImg">
          <img src={getImages('newsTitleImg')} alt="" />
        </div>
        <div className="newsContainer-content">
          <div className="newsContainer-content__title">
            <img
              src={home}
              alt=""
              className="newsContainer-content__title__home"
            />
            <img
              src={group}
              alt=""
              className="newsContainer-content__title__group"
            />
            <div className="newsContainer-content__title__text">
              {getLabel('xinweizixun')}
            </div>
          </div>
          <div className="newsContainer-content__center">
            <div className="newsContainer-content__center__top">Our News</div>
            <div className="newsContainer-content__center__flex">
              <div className="newsContainer-content__center__flex__left">
                {getLabel('xinwendongtai2')}
              </div>
              <div className="newsContainer-content__center__flex__right">
                <div
                  className={
                    informationTypeIs === 'COMPANY'
                      ? 'newsContainer-content__center__flex__right__newChoose'
                      : 'newsContainer-content__center__flex__right__new'
                  }
                  onClick={() => {
                    setInformationTypeIs('COMPANY');
                  }}
                >
                  {getLabel('gongsixinwen')}
                </div>
                <div
                  className={
                    informationTypeIs === 'INDUSTRY'
                      ? 'newsContainer-content__center__flex__right__dynamicChoose'
                      : 'newsContainer-content__center__flex__right__dynamic'
                  }
                  onClick={() => {
                    setInformationTypeIs('INDUSTRY');
                  }}
                >
                  {getLabel('hangyedongtai')}
                </div>
              </div>
            </div>

            <div className="newsContainer-content__center__box">
              {newsList?.map((item, index) => {
                return (
                  <>
                    <div
                      className="newsContainer-content__center__box__item"
                      key={index}
                    >
                      <div className="newsContainer-content__center__box__item__left">
                        <div className="newsContainer-content__center__box__item__left__img">
                          <img src={JSON.parse(item?.img)} loading="lazy"></img>
                        </div>
                        <div className="newsContainer-content__center__box__item__left__month">
                          <div className="newsContainer-content__center__box__item__left__month__calanderIcon">
                            <img src={calanderIcon} loading="lazy" />
                          </div>

                          <>
                            <div className="newsContainer-content__center__box__item__left__month__text">
                              {item?.dateString?.day ?? ''}
                            </div>
                            <div className="newsContainer-content__center__box__item__left__month__textEn">
                              {(isChinese()
                                ? item?.dateString?.chineseMonth
                                : item?.dateString?.englishMonth) ?? ''}
                            </div>
                          </>
                        </div>
                      </div>

                      <div className="newsContainer-content__center__box__item__right">
                        <div className="newsContainer-content__center__box__item__right__title">
                          {isChinese() ? item?.title : item?.enTitle}
                        </div>
                        <div className="newsContainer-content__center__box__item__right__content">
                          {isChinese() ? item?.subTitle : item?.enSubTitle}
                        </div>
                        <div
                          className="newsContainer-content__center__box__item__right__toDetail"
                          onClick={() => {
                            vsf?.navigateTo(`./newsDetail?id=${item?.id}`);
                          }}
                        >
                          {getLabel('chakanxiangqing')}{' '}
                          <ArrowRightOutlined className="newsContainer-content__center__box__item__right__toDetailIcon" />
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>

            <div className="newsContainer-content__center__Pagination">
              <Pagination
                defaultCurrent={1}
                defaultPageSize={pageSize}
                total={total}
                showSizeChanger={false}
                onChange={handleChange}
                className="my-pagination"
              />
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default definePage(News);
